import { UseQueryOptions, useQuery } from "@tanstack/react-query"
import { APIS_KEYS } from "../../apis-keys"
import { IPropsGetInvoices, IResponseGetInvoices } from "./types"
import type { AxiosInstance, AxiosResponse } from "axios"
import { useApi } from "../../useApi"
import { getInvoicesURL } from "../../../utils/constants"

async function getInvoices(
	{ ...rest }: IPropsGetInvoices,
	apiPrivate: AxiosInstance
) {
	try {
		const response: AxiosResponse<IResponseGetInvoices> =
			await apiPrivate.get(`${getInvoicesURL}`, {
				params: rest,
			})
		return response.data
	} catch (error: any) {
		console.log(error)
		throw new Error("something went wrong")
	}
}

export function useGetInvoices(
	data: IPropsGetInvoices,
	options?:
		| Omit<
				UseQueryOptions<
					IResponseGetInvoices,
					unknown,
					IResponseGetInvoices
				>,
				"queryKey" | "queryFn"
		  >
		| undefined
) {
	const { apiPrivate } = useApi()
	return useQuery({
		queryKey: [APIS_KEYS.GETINVOICES, ...Object.values(data)],
		queryFn: () => getInvoices(data, apiPrivate),
		...options,
	})
}
