import { UseQueryOptions, useQuery } from "@tanstack/react-query"
import { APIS_KEYS } from "../../apis-keys"
import { IResponseGetOnboardingMotives } from "./types"
import type { AxiosInstance, AxiosResponse } from "axios"
import { useApi } from "../../useApi"
import { getOnboardingMotivesURL } from "../../../utils/constants"

async function getOnboardingMotives(apiPrivate: AxiosInstance) {
	try {
		const response: AxiosResponse<IResponseGetOnboardingMotives> =
			await apiPrivate.get(`${getOnboardingMotivesURL}`)
		return response.data
	} catch (error: any) {
		console.log(error)
		throw new Error("something went wrong")
	}
}

export function useGetOnboardingMotives(
	options?:
		| Omit<
				UseQueryOptions<
					IResponseGetOnboardingMotives,
					unknown,
					IResponseGetOnboardingMotives
				>,
				"queryKey" | "queryFn"
		  >
		| undefined
) {
	const { apiPrivate } = useApi()
	return useQuery({
		queryKey: [APIS_KEYS.GETONBOARDINGMOTIVES],
		queryFn: () => getOnboardingMotives(apiPrivate),
		...options,
	})
}
