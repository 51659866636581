import { FC, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTranslation } from 'react-i18next';
import Fetching from '@assets/svg/fetching.svg?react';
import LinkingComponent from './LinkingComponent';
import LinkError from './LinkError';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import HomeIcon from '@assets/svg/design-system/home.svg?react';

const PropertiesList: FC<{
  propertyList?: any[];
  mutate: (body: any[]) => void;
  reset: (close: boolean) => void;
  loading: boolean;
  mutating: boolean;
  success: boolean;
  error: boolean;
  channel: string;
  channelIcon: any;
  onNext: () => void
  name?:string
}> = ({ propertyList, mutate, loading, mutating, channelIcon, reset, channel, error, success, onNext = () => { },name='' }) => {
  const { t } = useTranslation();
  const [selectedProperty, setSelectedProperty] = useState<any[]>([]);

  const onLink = () => {
    mutate(selectedProperty);
  };

  const getBorderColor = (id: any, isConncted: boolean) => {
    if (isSelecedProperty(id)) {
      return '#5B3FFF';
    }
    if (isConncted) {
      return '#EAEDF1';
    }
    return '#EEEBFF';
  };

  const getBgColor = (id: any, isConncted: boolean) => {
    if (isSelecedProperty(id)) {
      return '#EEEBFF';
    }
    if (isConncted) {
      return '#EAEDF1';
    }
    return '#FFF';
  };

  const isSelecedProperty = (id: any) => {
    return selectedProperty?.some((s) => s.id == id);
  };
  const getCursor = (id: any, isConncted: boolean) => {
    if (isSelecedProperty(id)) {
      return 'auto';
    }
    if (isConncted) {
      return 'auto';
    }
    return 'pointer';
  };
  if (mutating) {
    return <LinkingComponent channelIcon={channelIcon} />;
  }

  if (loading) {
    return (
      <Box sx={{ flex: 1, pt: 4, pb: 2, display: 'flex', flexDirection: 'column' }}>
        <Typography color={'#0F172A'} fontSize={'20px'}>
          {t('ADD_PROPERTY.FETCH_STEP', { channel })}
        </Typography>
        <Typography color={'#8E98A1'} fontSize={'16px'}>
          {t('ADD_PROPERTY.FETCH_STEP_DESCRIPTION')}
        </Typography>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
            flexDirection: 'column',
            svg: {
              width: '60px',
              height: '60px',
            },
          }}
        >
          <Fetching />
          <Typography>{t('ADD_PROPERTY.FETCHING')}</Typography>
        </Box>
      </Box>
    );
  }
  if (success) {
    return (
      <Box
        sx={{
          flex: 1,
          pt: 4,
          pb: 2,
          gap: 2,
          display: 'flex',
          flexDirection: 'column',
          svg: {
            width: '80px',
            height: '80px',
            color: '#16AE26',
          },
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <CheckCircleIcon />
          {t('ADD_PROPERTY.LINK_SUCCESS', { channel })}
          <Box sx={{ textAlign: 'center' }}>
            <Button size={'small'} variant={'contained'} onClick={() => onNext()}>
              {t('COMMON.NEXT')}
            </Button>
          </Box>
        </Box>

        {/* <Box sx={{ textAlign: 'center' }}>
          <Button size={'small'} variant={'contained'} onClick={() => reset(success)}>
            {t(`ADD_PROPERTY.MANAGE`)}
          </Button>
        </Box> */}
      </Box>
    );
  }
  if (error) {
    return <LinkError reset={reset} channel={channel} />;
  }

  return (
    <Box sx={{ flex: 1, pt: 4, pb: 2, display: 'flex', flexDirection: 'column' }}> 
      <Typography color={'#0F172A'} fontSize={'20px'}>
        {channel == 'Gathern' ? `أختر العقارات المراد ربطها مع  ${name}` : t('ADD_PROPERTY.CHOOSE_STEP')}
      </Typography>
      <Typography color={'#8E98A1'} fontSize={'16px'}>
        {t('ADD_PROPERTY.CHOOSE_STEP_DESCRIPTION')}
      </Typography>

      <Box
        sx={{
          pt: 3,
          display: 'grid',
          gridTemplateColumns: { xs: 'repeat(2,1fr)', sm: 'repeat(3,1fr)' },
          gap: 2,
          marginBottom: 4,
          borderBlockEnd: '1px solid #EAEDF1',
          paddingBottom: 3,
        }}
      >
        {propertyList?.map((p: any) => (
          <Box
            key={p.id}
            onClick={() => {
              if (!p.isConncted) {
                setSelectedProperty((prev) => {
                  if (isSelecedProperty(p.id)) {
                    return prev.filter((i) => i.id != p.id);
                  } else {
                    return [...prev, p];
                  }
                });
              }
            }}
            sx={{
              height: '100%',
              border: '2px solid',
              p: 2,
              borderColor: getBorderColor(p.id, p.isConncted),
              backgroundColor: getBgColor(p.id, p.isConncted),
              cursor: getCursor(p.id, p.isConncted),
              borderRadius: '16px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                svg: {
                  width: '42px',
                  height: '42px',
                },
              }}
            >
              {p?.image ? <img className={'size-20 object-cover rounded-full'} src={p.image} /> : <HomeIcon />}
            </Box>
            <Typography textAlign={'center'}>{p.title}</Typography>
            {isSelecedProperty(p.id) && <CheckCircleIcon color={'primary'} fontSize={'small'} />}

            {p.isConncted && (
              <Box
                sx={{
                  fontSize: '12px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  background: '#EEEBFF',
                  border: '1px solid #5B3FFF',
                  color: '#5B3FFF',
                  mt: 1,
                  px: 1,
                  py: 0.5,
                  borderRadius: '1rem',
                }}
              >
                <SwapHorizIcon fontSize={'small'} />
                {t('ADD_PROPERTY.ALREADY_LINKED')}
              </Box>
            )}
          </Box>
        ))}
      </Box>
      <Box sx={{ mt: 'auto', display: 'flex', gap: 8, alignItems: 'center' }}>
        <Button size={'small'} variant={'contained'} onClick={onLink} disabled={!selectedProperty?.length}>
          {t('ADD_PROPERTY.LINK')}
        </Button>
        <Typography color={'#8E98A1'}>{t('ADD_PROPERTY.LINK_HELPER')}</Typography>
      </Box>
    </Box>
  );
};
export default PropertiesList;
