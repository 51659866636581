import { UseQueryOptions, useQuery } from "@tanstack/react-query"
import { APIS_KEYS } from "../../apis-keys"
import { IPropsGetActivePlan, IResponseGetActivePlan } from "./types"
import type { AxiosInstance, AxiosResponse } from "axios"
import { useApi } from "../../useApi"
import { getActiveSubscriptionURL } from "../../../utils/constants"

async function getActivePlan(
	{ ...rest }: IPropsGetActivePlan,
	apiPrivate: AxiosInstance
) {
	try {
		const response: AxiosResponse<IResponseGetActivePlan> =
			await apiPrivate.get(`${getActiveSubscriptionURL}`, {
				params: rest,
			})
		return response.data
	} catch (error: any) {
		console.log(error)
		throw new Error("something went wrong")
	}
}

export function useGetActivePlan(
	data: IPropsGetActivePlan,
	options?:
		| Omit<
				UseQueryOptions<
					IResponseGetActivePlan,
					unknown,
					IResponseGetActivePlan
				>,
				"queryKey" | "queryFn"
		  >
		| undefined
) {
	const { apiPrivate } = useApi()
	return useQuery({
		queryKey: [APIS_KEYS.GETACTIVEPLAN, ...Object.values(data)],
		queryFn: () => getActivePlan(data, apiPrivate),
		...options,
	})
}
