import { UseQueryOptions, useQuery } from "@tanstack/react-query"
import { APIS_KEYS } from "../../apis-keys"
import { IPropsGetPrintInvoice, IResponseGetPrintInvoice } from "./types"
import type { AxiosInstance, AxiosResponse } from "axios"
import { useApi } from "../../useApi"
import { getPrintInvoiceURL } from "../../../utils/constants"

async function getPrintInvoice(
	{ invoiceId }: IPropsGetPrintInvoice,
	apiPrivate: AxiosInstance
) {
	try {
		const response: AxiosResponse<IResponseGetPrintInvoice> =
			await apiPrivate.get(`${getPrintInvoiceURL}?invoiceId=${invoiceId}`, {
				// responseType: "arraybuffer",
				responseType: "blob",
			})
		const file = new Blob([response?.data as any], {
			type: "application/pdf",
		})
		const fileURL = URL.createObjectURL(file)
		const fileLink = document.createElement("a")
		fileLink.href = fileURL
		fileLink.download = "invoice"
		fileLink.click()
		return response.data
	} catch (error: any) {
		console.log(error)
		throw new Error("something went wrong")
	}
}

export function useGetPrintInvoice(
	data: IPropsGetPrintInvoice,
	options?:
		| Omit<
				UseQueryOptions<
					IResponseGetPrintInvoice,
					unknown,
					IResponseGetPrintInvoice
				>,
				"queryKey" | "queryFn"
		  >
		| undefined
) {
	const { apiPrivate } = useApi()
	return useQuery({
		queryKey: [APIS_KEYS.GETPRINTINVOICE, ...Object.values(data)],
		queryFn: () => getPrintInvoice(data, apiPrivate),
		...options,
	})
}
