import styled from "styled-components"

export const CustomPhoneNumberInputWrapper = styled.div<{
	$language: "ar" | "en"
	$isError: boolean
}>`
	> label {
		font-weight: 700;
		text-align: start;
		color: #435363;
		visibility: visible;
		font-size: 15px;
		margin: 0;
		margin-bottom: 4px;
		text-transform: capitalize;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: flex;
		flex-direction: row;
		span {
			color: #d70015;
			margin-inline: 4px;
		}
	}
	.PhoneInput {
		padding: 6px 16px;
		height: 48px;
		border: 1px solid ${(props) => (props.$isError ? "#EF4444" : "#e5e7eb")};
		border-radius: 12px;
		flex-direction: ${(props) =>
			props.$language === "en" ? "row-reverse" : "row"};
		gap: 8px;
		.PhoneInputCountry {
			margin: 0;
			flex-direction: ${(props) =>
				props.$language === "en" ? "row-reverse" : "row"};
			gap: 8px;
		}
		.PhoneInputInput {
			border: none;
			border-right: 1px solid
				${(props) => (props.$isError ? "#EF4444" : "#e2e8f0")};
			padding-inline: 6px;
			height: 100%;
			text-align: right;
			outline: none;
			color: ${(props) => (props.$isError ? "#EF4444" : "#261B6A")};
		}
	}

	.ErrorMessageStyle {
		font-size: 13px;
		font-weight: 400;
		line-height: 22.4px;
		color: #ef4444;
		height: 22px;
		p {
			margin: 0;
		}
	}
`
