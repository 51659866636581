import styled from "styled-components"

export const PlansWrapper = styled.div`
	margin-inline: 10px;
	margin-bottom: 16px;
	height: 100%;
	padding: 20px;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto auto 1fr;
	gap: 20px;
	background: linear-gradient(0deg, #fafafb, #fafafb),
		linear-gradient(
			180deg,
			rgba(255, 255, 255, 0.2) 0%,
			rgba(255, 255, 255, 0) 100%
		);
	box-shadow: 0px 0px 0px 0.61px #eaeafd;
	border-radius: 24px;
	p {
		margin: 0;
		padding: 0;
	}
	.infoSection {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		gap: 20px;
		flex-direction: column;

		> p {
			width: 100%;
			font-size: 14px;
			font-weight: 700;
			color: #5b3fff;
		}
		.compo {
			width: 100%;
			p {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				gap: 12px;
				&:first-child {
					font-size: 27px;
					font-weight: 700;
					color: #261b6a;
				}
				.Strikethrough {
					font-size: 14px;
					font-weight: 400;
					text-decoration-line: line-through;
					color: #261b6a99;
				}
			}
			.plan {
				font-size: 14px;
				font-weight: 400;
				color: #261b6a99;
			}
		}
		.number_property {
			width: 100%;
			background-color: #f2f2ff;
			font-size: 12px;
			font-weight: 500;
			color: #5b3fff;
			padding: 8px;
			text-align: center;
			border-radius: 4px;
		}
	}

	.selectThePlan {
		border: 1px solid #f2f2f2f2;
		padding-block: 20px;
		border-inline: none;
	}

	.containerRow {
		p {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 4px;
			span {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				&:first-child {
					color: #5b3fff;
					font-size: 16px;
				}
				&:last-child {
					font-size: 16px;
					font-weight: 400;
					line-height: 24px;
					color: #261b6a;
				}
			}
		}
	}
`
