import { Dispatch, SetStateAction, useState } from "react"
import StepperState from "./components/stepperState"
import { Container, Grid } from "@mui/material"
import { StepperSignupWrapper } from "./styles"
import FormStepOne from "./components/formStepOne"
import FormStepTwo from "./components/formStepTwo"
import CustomButton from "@Common/CustomButton"
import { useTranslation } from "react-i18next"
import DescriptionSection from "./components/descriptionSection"
interface IProps {
	setOpenViewNum: Dispatch<SetStateAction<1 | 2>>
}
export default function StepperSignup({ setOpenViewNum }: IProps) {
	const { t } = useTranslation()
	const [stepCount] = useState(2) // total steps
	const [activeStep, setActiveStep] = useState<0 | 1>(0) //active step start from 0
	return (
		<StepperSignupWrapper>
			<Grid container>
				<Grid item xs={12} md={6}>
					<Container>
						<div className='containerItems'>
							<StepperState activeStep={activeStep} stepCount={stepCount} />
							{activeStep === 0 && (
								<FormStepOne setActiveStep={setActiveStep} />
							)}
							{activeStep === 1 && (
								<FormStepTwo setOpenViewNum={setOpenViewNum} />
							)}
							<div className='containerButton'>
								<CustomButton
									withBG
									label={t("BOOKING.NEXT")}
									borderRadius='8px'
									customPadding='16px'
									type='submit'
									form={activeStep === 0 ? "FormStepOne" : "FormStepTwo"}
								/>
							</div>
						</div>
					</Container>
				</Grid>
				<Grid item xs={0} md={6}>
					<DescriptionSection />
				</Grid>
			</Grid>
		</StepperSignupWrapper>
	)
}
