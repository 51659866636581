import { FormProvider, useForm } from "react-hook-form"
import { FormStepOneWrapper } from "./styles"
import { useTranslation } from "react-i18next"
import CardInput from "../cardInput"
import { Dispatch, SetStateAction, useContext, useMemo } from "react"
import { useGetOnboardingChannels, usePostSaveChannelsSurvey } from "@services"
import AirbnbIcon from "@assets/svg/airbnb.svg?react"
import GathernIcon from "@assets/svg/GathernIcon2.svg?react"
import BookingIcon from "@assets/svg/BookingIcon.svg?react"
import Agoda from "@assets/svg/Agoda.svg?react"
import MsoolIcon from "@assets/svg/msool-icon.svg?react"
import ExpediaSoon from "@assets/svg/ExpediaSoon.svg?react"
import Aqar from "@assets/svg/Aqar.svg?react"
import { ToastMessageContext } from "@context/toast-message-cotnext"
import { Grid } from "@mui/material"

interface IProps {
	setActiveStep: Dispatch<SetStateAction<0 | 1>>
}
interface IForm {
	test: string[]
}
interface CardData {
	id: string
	title: string
	imageUrl: string
}

const channals = {
	airbnb: <AirbnbIcon />,
	expedia: <ExpediaSoon />,
	gathern: <GathernIcon />,
	booking: <BookingIcon />,
	aqar: <Aqar />,
	agoda: <Agoda />,
	other: "Other",
	msool: <MsoolIcon />,
}
export default function FormStepOne({ setActiveStep }: IProps) {
	/* ---------------------------------- Hooks --------------------------------- */
	const { showWarning } = useContext(ToastMessageContext)
	const { data: GetListChannels } = useGetOnboardingChannels()
	const { mutateAsync: mutateAsyncSaveChannelsSurvey } =
		usePostSaveChannelsSurvey()
	const { t } = useTranslation()

	const methods = useForm<IForm>({
		defaultValues: {
			test: [],
		},
	})

	/* -------------------------------- Functions ------------------------------- */
	const onSubmit = async (value: IForm) => {
		if (value.test.length === 0)
			return showWarning(t("paymentPlan.please_select_at_least_one_channel"))

		if (value.test.length !== 0) {
			const resData = value.test.map((item) => {
				return {
					optionId: item,
					value: "",
				}
			})
			const response = await mutateAsyncSaveChannelsSurvey({
				data: resData,
			})
			if (response.status === 200) {
				setActiveStep(1)
			}
		}
	}

	const cards = useMemo(() => {
		const data = GetListChannels?.result.map((item) => {
			return {
				id: item.id.toString(),
				title: item.name,
				imageUrl:
					channals[
						item.name.toLowerCase() as
							| "airbnb"
							| "expedia"
							| "gathern"
							| "booking"
							| "aqar"
							| "agoda"
							| "other"
							| "msool"
					],
			}
		})
		return data ?? []
	}, [GetListChannels])

	return (
		<FormStepOneWrapper>
			<div className='containerHeader'>
				<h1>
					{t(
						"Signup.To_set_up_the_link_with_your_favorite_channels_choose_the_channels_that_have_the_most_impact_on_your_bookings"
					)}
				</h1>
				<p>{t("Signup.What_are_the_most_popular_platforms_you_market_on")}</p>
			</div>
			<Grid container>
				<Grid item xs={12} md={8}>
					<FormProvider {...methods}>
						<form onSubmit={methods.handleSubmit(onSubmit)} id='FormStepOne'>
							<div className='containerCards'>
								{cards.map((card) => (
									<CardInput
										key={card.id}
										id={card.id}
										title={card.title}
										imageUrl={card.imageUrl}
										name='test'
									/>
								))}
							</div>
						</form>
					</FormProvider>
				</Grid>
			</Grid>
		</FormStepOneWrapper>
	)
}
