import { StepperStateWrapper } from "./styles"
interface IProps {
	stepCount: number
	activeStep: number
}

export default function StepperState({ stepCount, activeStep }: IProps) {
	return (
		<StepperStateWrapper>
			<div className='stepperCotainer'>
				{[...Array(stepCount)].map((_, i) => (
					<div className={activeStep === i|| activeStep > i ? "active" : ""} key={i}></div>
				))}
			</div>
		</StepperStateWrapper>
	)
}
