import { UseMutationOptions, useMutation } from "@tanstack/react-query"
import { AxiosInstance, AxiosResponse } from "axios"
import { IPropsSaveMotivesSurvey, IResponseSaveMotivesSurvey } from "./types"
import { useApi } from "../../useApi"
import { SaveMotivesSurveyURL } from "../../../utils/constants"

async function postSaveMotivesSurvey(
	responseData: IPropsSaveMotivesSurvey,
	apiPrivate: AxiosInstance
) {
	try {
		const response: AxiosResponse<IResponseSaveMotivesSurvey> =
			await apiPrivate.post(`${SaveMotivesSurveyURL}`, responseData.data)

		return response
	} catch (error: any) {
		return error.response
	}
}

export function usePostSaveMotivesSurvey(
	options?:
		| Omit<
				UseMutationOptions<
					AxiosResponse<IResponseSaveMotivesSurvey>,
					unknown,
					unknown,
					AxiosResponse<IResponseSaveMotivesSurvey>
				>,
				"mutationFn"
		  >
		| undefined
) {
	const { apiPrivate } = useApi()
	const mutation = useMutation({
		mutationFn: (data: IPropsSaveMotivesSurvey) =>
			postSaveMotivesSurvey(data, apiPrivate),
		...options,
	})
	return mutation
}
