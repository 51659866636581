import styled from "styled-components"

export const SubscriptionPlansWrapper = styled.div`
	width: 100dvw;
	height: 100dvh;
	padding: 30px 60px;
	overflow-y: scroll;
	> div {
		overflow-y: scroll;
		background-color: #fff;
		border: 1px solid #eaeafd33;
		width: 100%;
		height: 100%;
		border-radius: 24px;
		padding: 20px;
		.Header {
			margin-bottom: 44px;
			h1,
			p {
				margin: 0;
				padding: 0;
				box-sizing: border-box;
				text-align: center;
				color: #331860;
			}
			h1 {
				font-size: clamp(26px, 5vw, 36px);
				font-weight: 700;
				line-height: 43.2px;
			}
			p {
				font-size: clamp(12px, 5vw, 20px);
				font-weight: 400;
				line-height: 24px;
			}
		}
	}
	@media only screen and (max-width: 600px) {
		padding: 0;
	}
`
