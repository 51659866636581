import styled from "styled-components"

export const StepperSignupWrapper = styled.div`
	height: 100%;
	width: 100%;
	border-radius: 24px;
	background-color: #fff;
	> div {
		height: inherit;
		> div {
			height: inherit;
			> div {
				height: inherit;
				padding-block: 20px;
				.containerItems {
					height: 100%;
					display: grid;
					grid-template-columns: 1fr;
					grid-template-rows: auto 1fr auto;
					gap: 60px;
				}
				.containerButton {
					button {
						width: 50%;
					}
				}
			}
		}
	}
`
