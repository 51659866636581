import CustomButton from "@Common/CustomButton"
import CustomPhoneNumberInput from "@Common/customPhoneNumberInput"
import Input from "@Common/input"
import { Grid } from "@mui/material"
import { FORM_REGEX_VALIDATORS } from "@utils"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { usePostRegister } from "@services"
import { useNavigate } from "react-router-dom"
import { ToastMessageContext } from "@context/toast-message-cotnext"
import { useContext } from "react"
import { LoadingWrapper } from "@components"
interface IForm {
	FullName: string
	Email: string
	PhoneNumber: string
	numberOfProperty: string
	Password: string
	confirm_password: string
	userType: string
	city: string
	channels: number[]
	isAgreeTerms: boolean
}

export default function FormInputs() {
	const { showError, showSuccess } = useContext(ToastMessageContext)

	const navigate = useNavigate()
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const methods = useForm<IForm>({
		defaultValues: {
			userType: "Default",
			city: "Default",
			channels: [0],
			isAgreeTerms: true,
		},
	})
	const { mutateAsync: mutateAsyncUsePostRegister, isPending } =
		usePostRegister()
	const { watch, reset } = methods
	const passwordWatch = watch("Password")
	const onSubmit = async (data: IForm) => {
		const response = await mutateAsyncUsePostRegister({ ...data })
		if (response.status === 200) {
			reset()
			navigate("/login")
			showSuccess(t("paymentPlan.the_account_has_been_created_successfully"))
		} else {
			const merged: any = Object.values(
				(response.data as any).errorMessages
			)?.reduce(
				(accumulator: any, currentValue) => accumulator.concat(currentValue),
				[]
			)
			merged.map((item: any) =>
				showError(language === "ar" ? item.messageAr : item.messageEn)
			)
		}
	}

	return (
		<LoadingWrapper loading={isPending}>
			<div>
				<FormProvider {...methods}>
					<form onSubmit={methods.handleSubmit(onSubmit)}>
						<Grid container>
							<Grid item xs={12}>
								<Input
									name='FullName'
									label={t("Signup.name")}
									placeholder={t("Signup.name")}
									registerOptions={{
										required: {
											value: true,
											message: t("COMMON.REQUIRED"),
										},
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<Input
									name='Email'
									label={t("Signup.email")}
									placeholder={t("Signup.email")}
									registerOptions={{
										pattern: {
											value: FORM_REGEX_VALIDATORS.email,
											message: t("COMMON.REQUIRED"),
										},
										required: {
											value: true,
											message: t("COMMON.REQUIRED"),
										},
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<CustomPhoneNumberInput
									defaultCountry='SA'
									countryCallingCodeEditable={false}
									initialValueFormat='national'
									label={t("ADD_PROPERTY.PHONE_NUMBER")}
									placeholder={t("ADD_PROPERTY.PHONE_NUMBER")}
									name='PhoneNumber'
									registerOptions={{
										required: {
											value: true,
											message: t("COMMON.REQUIRED"),
										},
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<Input
									name='numberOfProperty'
									label={t("Signup.num_of_propertys")}
									placeholder={t("Signup.num_of_propertys")}
									registerOptions={{
										pattern: {
											value: FORM_REGEX_VALIDATORS.numbersOnly,
											message: t("COMMON.THE_FIELD_ACCEPTS_NUMBERS_ONLY"),
										},
										required: {
											value: true,
											message: t("COMMON.REQUIRED"),
										},
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<Input
									name='Password'
									label={t("Signup.PASSWORD")}
									placeholder={t("Signup.PASSWORD")}
									type='password'
									registerOptions={{
										required: {
											value: true,
											message: t("COMMON.REQUIRED"),
										},
										minLength: {
											value: 8,
											message: t(
												"Signup.password_must_be_at_least_8_characters_long"
											),
										},
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<Input
									name='confirm_password'
									label={t("Signup.Confirm_PASSWORD")}
									placeholder={t("Signup.Confirm_PASSWORD")}
									type='password'
									registerOptions={{
										validate: (value) =>
											value === passwordWatch || t("Signup.PASSWORD_ERROR"),
										required: {
											value: true,
											message: t("COMMON.REQUIRED"),
										},
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<CustomButton
									withBG
									label={t("Signup.signup")}
									customPadding='16px'
									borderRadius='8px'
									type='submit'
								/>
							</Grid>
							<Grid item xs={12}>
								<div
									className='HAVE_ACCOUNT'
									onClick={() => navigate("/login")}
								>
									<p>
										{t("SUBSCRIPTION.HAVE_ACCOUNT")}
										<span>{t("SUBSCRIPTION.LOGIN")}</span>
									</p>
								</div>
							</Grid>
						</Grid>
					</form>
				</FormProvider>
			</div>
		</LoadingWrapper>
	)
}
