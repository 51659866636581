import { FormProvider, useFieldArray, useForm } from "react-hook-form"
import { AccessInstructionsWrapper } from "./styles"
import { useTranslation } from "react-i18next"
import SolarTrashBinMinimalisticLine from "@assets/svg/SolarTrashBinMinimalisticLine-duotone.svg?react"
import { Grid } from "@mui/material"
import Input from "@Common/input"
import TextArea from "@Common/Textarea"
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react"
import { IProperty } from "@interfaces"
import CustomUploadFiles from "@Common/customUploadFiles"
import { IoChevronDownOutline, IoChevronUpOutline } from "react-icons/io5"
import { FORM_REGEX_VALIDATORS } from "@utils"

interface IProps {
	templates?: any[]
	selected: IProperty[]
	removeProperty: (p: IProperty) => void
	setTab: React.Dispatch<React.SetStateAction<number>> | undefined
	setTemplateDetailsState?: Dispatch<
		SetStateAction<IFormAccessInstructions | undefined>
	>
}

export interface IFormAccessInstructions {
	templateDetails: {
		id: number
		propertyId: number
		propertyName: string
		location: string
		buildingNumber: string
		floorNumber: string
		apartmentNumber: 0
		wifiName: string
		wifiPassword: string
		descrption: string
		images: string[]
		deletedImages?: string[]
	}[]
}
export default function AccessInstructions({
	setTemplateDetailsState,
	setTab,
	selected,
	removeProperty,
	templates,
}: IProps) {
	const [activeTabe, setActiveTabe] = useState<Number | undefined>(undefined)
	const { t } = useTranslation()
	/* ------------------------- in case create templete ------------------------ */
	const getProperties = useMemo(() => {
		const data = selected.map((item) => {
			return {
				propertyId: item.propertyId,
				propertyName: item.propertyName,
			}
		})
		return data ?? []
	}, [selected])
	/* -------------------------- in case edit templete ------------------------- */
	const mergedProperties = useMemo(() => {
		return getProperties?.map((property) => {
			const matchingTemplate = templates?.find(
				(template) => template.propertyId === property.propertyId
			)

			if (matchingTemplate) {
				const dataRes = {
					...property,
					...matchingTemplate,
					location: matchingTemplate.location,
					description: matchingTemplate.descrption,
					wifiName: matchingTemplate.wifiName,
					wifiPassword: matchingTemplate.wifiPassword,
					id: matchingTemplate.id,
					images: matchingTemplate.image?.map((img: any, index: number) => ({
						id: index,
						url: img.orignialUrl,
					})),
				}
				delete dataRes.image
				return dataRes
			}
			return property
		})
	}, [getProperties, templates])

	const methods = useForm<IFormAccessInstructions>({
		defaultValues: {
			templateDetails: [],
		},
	})
	const { control } = methods
	const { fields, remove } = useFieldArray<IFormAccessInstructions>({
		control,
		name: "templateDetails",
	})
	const onSubmit = (data: IFormAccessInstructions) => {
		if (!setTab || !setTemplateDetailsState) return
		setTemplateDetailsState(data)
		setTab(3)
	}

	const deleteImg =  (propertyId: number, imageIndex: string): Promise<{ id: string; URL: string }[]> => {
    const property = mergedProperties.find((item) => item.propertyId === propertyId);

    if (property && property.images && Number(imageIndex) >= 0 && Number(imageIndex) < property.images.length) {
        // Get the image to be deleted
        const deletedImage = property.images[Number(imageIndex)].url;

        // Remove the image from the images array
        property.images.splice(Number(imageIndex), 1);

        // Initialize the deletedImages array if not already present
        if (!property.deletedImages) {
            property.deletedImages = [];
        }

        // Add the deleted image URL to the deletedImages array
        property.deletedImages.push(deletedImage);

        // Update the form with the modified template details
        methods.reset((formData) => {
            const updatedTemplateDetails = formData.templateDetails.map((item) =>
                item.propertyId === propertyId
                    ? { ...item, images: property.images, deletedImages: property.deletedImages }
                    : item
            );

            return {
                ...formData,
                templateDetails: updatedTemplateDetails,
            };
        });

        // Return the updated images array
        return property.images.map((img: { url: string }, index: number) => ({
            id: index.toString(), // Generate an ID
            URL: img.url,
        }));
    } else {
        console.log("Invalid image index or image array not found.");
        throw new Error("Image deletion failed: Invalid index or property.");
    }
};


	useEffect(() => {
		methods.reset({
			templateDetails: mergedProperties,
		})
	}, [mergedProperties, methods])

	return (
		<AccessInstructionsWrapper>
			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)} id='AccessInstructions'>
					{fields.map((item, index) => {
						return (
							<div key={index}>
								<div className='containerHeaderSection'>
									<div>
										<div
											onClick={() => {
												remove(index)
												removeProperty({
													propertyId: item.propertyId,
												})
											}}
										>
											<SolarTrashBinMinimalisticLine />
										</div>
									</div>
									<div>
										<p>{item.propertyName}</p>
									</div>
									<div
										onClick={() =>
											setActiveTabe((prev) =>
												prev === index ? undefined : index
											)
										}
									>
										<div>{t("COMMON.instructions_for_arriving")}</div>
										<div>
											{activeTabe === index ? (
												<IoChevronUpOutline />
											) : (
												<IoChevronDownOutline />
											)}
										</div>
									</div>
								</div>
								{activeTabe === index && (
									<div className='formInputs'>
										<Grid container>
											<Grid item xs={12}>
												<Input
													name={`templateDetails.${index}.location`}
													label={t("COMMON.LOCATION")}
													placeholder='عنوان جوجل مابس'
												/>
											</Grid>
											<Grid item xs={12} md={4}>
												<Input
													name={`templateDetails.${index}.buildingNumber`}
													label={t("COMMON.building_no")}
													placeholder={t("COMMON.building_no")}
													registerOptions={{
														pattern: {
															value: FORM_REGEX_VALIDATORS.numbersOnly,
															message: t(
																"COMMON.THE_FIELD_ACCEPTS_NUMBERS_ONLY"
															),
														},
													}}
												/>
											</Grid>
											<Grid item xs={12} md={4} paddingInline='8px'>
												<Input
													name={`templateDetails.${index}.floorNumber`}
													label={t("COMMON.floor_no")}
													placeholder={t("COMMON.floor_no")}
													registerOptions={{
														pattern: {
															value: FORM_REGEX_VALIDATORS.numbersOnly,
															message: t(
																"COMMON.THE_FIELD_ACCEPTS_NUMBERS_ONLY"
															),
														},
													}}
												/>
											</Grid>
											<Grid item xs={12} md={4}>
												<Input
													name={`templateDetails.${index}.apartmentNumber`}
													label={t("COMMON.apartment_no")}
													placeholder={t("COMMON.apartment_no")}
													registerOptions={{
														pattern: {
															value: FORM_REGEX_VALIDATORS.numbersOnly,
															message: t(
																"COMMON.THE_FIELD_ACCEPTS_NUMBERS_ONLY"
															),
														},
													}}
												/>
											</Grid>

											<Grid item xs={12} md={6} paddingInlineEnd='4px'>
												<Input
													name={`templateDetails.${index}.wifiName`}
													label={t("COMMON.wifi_name")}
													placeholder={t("COMMON.wifi_name")}
												/>
											</Grid>
											<Grid item xs={12} md={6} paddingInlineStart='4px'>
												<Input
													name={`templateDetails.${index}.wifiPassword`}
													label={t("COMMON.wifi_password")}
													placeholder={t("COMMON.wifi_password")}
												/>
											</Grid>
											<Grid item xs={12}>
												<TextArea
													name={`templateDetails.${index}.descrption`}
													label={t("COMMON.message_details")}
													placeholder={t("COMMON.message_details")}
												/>
											</Grid>
											<Grid item xs={12}>
												<CustomUploadFiles
													maxFilesUpload={2}
													name={`templateDetails.${index}.images`}
													deleteImageAPI={(docsId) => deleteImg(item.propertyId, docsId).then(() => {})}
													Buttonlabel={
														<p>
															<span>{t("COMMON.click_to_download")}</span>{" "}
															<span>{t("COMMON.or_drag_and_drop")}</span>
														</p>
													}
												/>
											</Grid>
										</Grid>
									</div>
								)}
							</div>
						)
					})}
				</form>
			</FormProvider>
		</AccessInstructionsWrapper>
	)
}
