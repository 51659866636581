// @ts-nocheck
import { useTranslation } from "react-i18next"
import { DescriptionSectionWrapper } from "./styles"
import BGPattern from "@assets/svg/BG_Pattern.svg"

export default function DescriptionSection() {
	const { t } = useTranslation()
	return (
		<DescriptionSectionWrapper>
			<div className='containerBG'>
				{BGPattern && <img src={BGPattern} alt='imag' />}
			</div>
			<div className='description'>
				<h1>
					{t(
						"Signup.enhance_your_collaboration_with_us_by_adding_and_managing_your_existing_properties"
					)}
				</h1>
				<p>
					{t(
						"Signup.we_are_responsible_for_providing_a_central_solution_for_hosts_to_manage_multiple_properties_from_different_shared_rental_platforms"
					)}
				</p>
			</div>
		</DescriptionSectionWrapper>
	)
}
