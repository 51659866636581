import styled from "styled-components"

export const FormSectionWrapper = styled.div`
	.HAVE_ACCOUNT {
		margin-top: 24px;
		p {
			margin: 0;
			text-align: center;
			font-size: 14px;
			font-weight: 500;
			line-height: 24px;
			color: #9ca3af;
			span {
				font-size: 14px;
				font-weight: 700;
				line-height: 24px;
				color: #5b3fff;
        cursor: pointer;
			}
		}
	}
`
