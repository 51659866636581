import styled from "styled-components"

export const CustomButtonWrapper = styled.button<{
	$withBG: boolean
	$customPadding: string | undefined
	$fontSize: string | undefined
	$borderRadius: string | undefined
	$iconPosition?: "rtl" | "ltr"
	$borderColor?: string
	$customBG?: string
	$customColor?: string
}>`
	border: ${(props) => (props.$withBG ? "none" : "1px solid #8E98A1")};
	border-color: ${(props) =>
		props.$borderColor ? props.$borderColor : "none"};
	background-color: ${(props) =>
		props.$withBG && !props.$customBG
			? "#5b3fff"
			: !props.$withBG && props.$customBG
				? props.$customBG
				: "#fff"};
	color: ${(props) =>
		props.$withBG && !props.$customColor
			? "#fff"
			: !props.$withBG && props.$customColor
				? props.$customColor
				: "#435363"};

	padding-block: 9px;
	padding: ${(props) =>
		props.$customPadding ? props.$customPadding : "9px 0"};
	border-radius: ${(props) =>
		props.$borderRadius ? props.$borderRadius : "20px"};
	width: 100%;
	font-size: ${(prosp) => (prosp.$fontSize ? "16px" : "")};
	font-weight: 700;
	line-height: 19.2px;
	direction: ${(props) => (props.$iconPosition ? props.$iconPosition : "rtl")};
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	gap: 8px;
	white-space: nowrap;
	&:disabled {
		background-color: #ccc;
		cursor: not-allowed;
	}
	cursor: pointer;
	span {
		display: flex;
		justify-content: center;
		align-items: center;
	}
`
