/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-nocheck
import { FC, useContext, useEffect, useState, useMemo } from 'react';
import { Box, Button, Select, IconButton, Modal, TextField, Typography, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { IModalProps } from '@interfaces';
//airbnb
import GATHREN from '@assets/svg/gathren.svg?react';
import AIRBNB from '@assets/svg/airbnb.svg?react';
import { ToastMessageContext } from '@context';
import { disabledFeatures } from '@utils';
import { useProperty } from '@services';
import BookingSoon from '@assets/svg/BookingSoon.svg?react';
import AgoodaSoon from '@assets/svg/AgoodaSoon.svg?react';
import GathernLarge from '@assets/svg/GathernLarge.svg?react';
import AirbnbLarge from '@assets/svg/AirbnbLarge.svg?react';
import useBreakPoinst from '@hooks/useBreakPoinst';
import CircularProgress from '@mui/material/CircularProgress';

const ConnectNewChannel: FC<IModalProps> = ({ handleClose, open, data,onAddNewProperyClicked=()=>{} }) => {  
  const { isPhone } = useBreakPoinst();
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isPhone ? '90%' : 495,
    bgcolor: 'background.paper',
    color: '#261B6A',
    boxShadow: 24,
    p: 3,
    borderRadius: 5,
  };

  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { connectToNewChannelMutate, gathrenListingQuery, getAirbnbListingQuery } = useProperty(); 

  const {
    data: gathrenListing,
    isLoading: gathrenListingLoading,
    isError: gathrenListingError,
    refetch,
  } = gathrenListingQuery();

  const {
    data: getAirbnbListing,
    isLoading: getAirbnbListingLoading,
    isError: getAirbnbListingError,
    refetch: getAirbnbListingRefetch,
    isRefetching:getAirbnbListingRefreshing
  } = getAirbnbListingQuery(localStorage.getItem('channexChannelId') ?? '');
  useEffect(() => {
    refetch();
    getAirbnbListingRefetch()
  }, []);

  const [phone, setPhone] = useState('');

  const searchParams = new URLSearchParams(location.search);

  const [gathernToken, setGathernToken] = useState(false);

  const [channexChannelId, setChannexChannelId] = useState(false);

  const [channels, setChannels] = useState('');

  const { showSuccess, showError } = useContext(ToastMessageContext);
  const [property, setProperty] = useState<any>(null);

  const [step, setStep] = useState(0);

  const [showOtp, setShowOtp] = useState(false);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const { mutate: connectNewChannelMutaion, isPending } = connectToNewChannelMutate({
    onSuccess: () => {
      showSuccess(t('PROPERTIES_MANAGEMENT.CONNECTED_SUCCESS'));
      handleClose();
    },
    onError: () => {
      showError(t('ERRORS.SOMETHING_WENT_WRONG'));
    },
  });
  const handleChange = (event: any) => {
    setPhone(event.target.value);
  };

  const handleStep = (value: number) => {
    if (channels === 'AIRBNB') {
      if (channexChannelId) {
        setStep(value);
      } else {
        airbnbCreateGroupAndProperty();
      }
    }

    if (channels === 'GATHREN') {
      setStep(value);
    }
  };

  const {
    control: control1,
    handleSubmit: handleSubmit1,
    reset: reset1,
    watch: watch1,
    setValue: setValue1,
    formState: { errors: errors1 },
  } = useForm<any>({
    mode: 'onChange',
  });

  const {
    control: control2,
    handleSubmit: handleSubmit2,
    watch: watch2,
    reset: reset2,
    setValue: setValue2,
    formState: { errors: errors2 },
  } = useForm<any>({
    mode: 'onChange',
  });

  const { gathrenLoginMutate, gathrenVerifyOtpMutate, addPropertyMutate, airbnbCreateGroupAndPropertyMutate } =
    useProperty();

  const { mutate: addProperty } = addPropertyMutate({
    onSuccess: () => {
      closeModal();
      showSuccess(t('PROPERTIES_MANAGEMENT.ADD_SUCCESS'));
    },
    onError: () => {
      showError(t('ERRORS.SOMETHING_WENT_WRONG'));
    },
  });

  const { mutate: airbnbCreateGroupAndProperty } = airbnbCreateGroupAndPropertyMutate({
    onSuccess: () => {},
    onError: () => {
      showError(t('ERRORS.SOMETHING_WENT_WRONG'));
    },
  });

  const { mutate: gathrenVerifyOtp } = gathrenVerifyOtpMutate({
    onSuccess: () => {
      setShowOtp(false);
      setGathernToken(true);
    },
    onError: () => {
      showError(t('ERRORS.SOMETHING_WENT_WRONG'));
    },
  });

  const onSubmit2 = (body: any) => {
    const payload: any = {
      propertyLocation: channels === 'GATHREN' ? body?.property?.location?.address : body?.property?.city,
      propertyId: 0,
      propertyName: body?.propertyName,
      propertyIsDeteled: false,
      propertyCreateDate: null,
      gathrenUnitId: channels === 'GATHREN' ? `${body?.property?.units[0]?.id}` : '',
      airbnbUnitId: channels === 'GATHREN' ? `` : `${body?.property?.id}`,
    };
    addProperty(payload);
  };

  const closeModal = () => {
    handleClose();
    setStep(0);
    setShowOtp(false);
    reset1({});
    reset2({});
    setShowConfirmation(false);
    setChannels('');
  };

  const gathernUnits = useMemo(() => {
    const result: any = [];
    gathrenListing?.chalets?.map((c: any) => {
      c.units.map((cc: any) => {
        result.push(cc);
      });
    });
    return result;
  }, [gathrenListing]);

  useEffect(() => {
    const x = localStorage.getItem('gathernToken');
    const channId = localStorage.getItem('channexChannelId');

    if (x !== null && x !== 'null') {
      setGathernToken(true);
    } else {
      // Handle the case when x is null
      setGathernToken(false); // or perform any other necessary action
    }
    if (channId !== null && channId !== 'null') {
      setChannexChannelId(true);
    } else {
      setChannexChannelId(false); // or perform any other necessary action
    }

    const success = searchParams.get('success');
    const channexChannelId = searchParams.get('channel_id');
    if (success && channexChannelId) {
      setChannels('AIRBNB');
      setStep(1);
    }
  }, [open, localStorage.getItem('gathernToken'), localStorage.getItem('channexChannelId')]);
  const AirbnbUnits = getAirbnbListing?.response?.data?.listing_id_dictionary?.values
  const RenderAddNew = ()=>{
    return <Box sx={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}} >
    <Typography sx={{color:'rgba(180, 186, 193, 1)',mb:1}} >
  {t('PROPERTIES_MANAGEMENT.NO_FLATES')}
    </Typography>
    <Typography onClick={onAddNewProperyClicked} sx={{color:'rgba(91, 63, 255, 1)',fontWeight:700,cursor:'pointer'}} >
  {t('PROPERTIES_MANAGEMENT.ADD_NEW_FLAT')}
    </Typography>
  </Box>
  }

  return (
    <>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <div className='flex justify-between items-center mb-3'>
            <Typography id='modal-modal-title' className='text-[#27272A]'>
              {t('PROPERTIES_MANAGEMENT.TITLE')}
            </Typography>

            <IconButton aria-label='close' onClick={closeModal} size='small'>
              <CloseIcon className='text-[#27272A] ' />
            </IconButton>
          </div>
          <>
            <>
              <div className='text-[#261B6A] font-normal mt-4'>
                <div>{t('PROPERTIES_MANAGEMENT.PROPERTY_NAME')}</div>
                <div className='mt-4 mb-4'>
                  <TextField
                    disabled
                    id='phone'
                    className='w-full rounded-3xl'
                    InputProps={{ sx: { borderRadius: 24 } }}
                    style={{ borderRadius: '24px !important', color: '#9CA3AF', borderColor: '#9CA3AF' }}
                    placeholder={`500000000`}
                    variant='outlined'
                    value={data?.propertyName}
                  />
                </div>
                <div className='mb-4'>{t('PROPERTIES_MANAGEMENT.ACTIVATED_CHANNELS')}</div>
                {!data?.airbnbUnitId && !data?.gathrenUnitId && (
                  <Box>{t('PROPERTIES_MANAGEMENT.NO_Connected_Channel')}</Box>
                )}
                {data?.airbnbUnitId && <AirbnbLarge style={{ width: 182, height: 48 }} />}
                {data?.gathrenUnitId && (
                  <GathernLarge style={{ width: 182, height: 48, marginLeft: 5, marginRight: 5 }} />
                )}
                <div className='mt-3'>{t('PROPERTIES_MANAGEMENT.CHANNELS_CONNECTION')}</div>
                <div className='mt-4 flex gap-4'>
                  {!data?.airbnbUnitId && (
                    <AirbnbLarge
                      onClick={() => {
                        setChannels('AIRBNB');
                        setProperty(null);
                      }}
                      style={{ width: 182, height: 48, opacity: channels == 'AIRBNB' ? 1 : 0.3 }}
                    />
                  )}
                  {!data?.gathrenUnitId && (
                    <GathernLarge
                      onClick={() => {
                        setChannels('GATHREN');
                        setProperty(null);
                      }}
                      style={{ width: 182, height: 48, opacity: channels == 'GATHREN' ? 1 : 0.3 }}
                    />
                  )}
                  <BookingSoon
                         onClick={() => {
                          if(disabledFeatures.booking)return
                          setChannels('BOOKING');
                          setProperty(null);
                        }}
                       style={
                        disabledFeatures.booking?{ width: 182, height: 48 } :
                        { width: 182, height: 48, opacity: channels == 'BOOKING' ? 1 : 0.7 }}
                    // style={{ width: 182, height: 48 }}

                  
                  />
                  <AgoodaSoon style={{ width: 182, height: 48 }} />
                </div>
              </div>
            </>
            <div className='text-[#261B6A] font-normal pt-6'>
              <div>
                {channels === "BOOKING"?t('PROPERTIES_MANAGEMENT.SELECT_APARTMENT_IN_BOOKING'): 'GATHREN'
                  ? t('PROPERTIES_MANAGEMENT.SELECT_APARTMENT') 
                  : t('PROPERTIES_MANAGEMENT.SELECT_APARTMENT_IN_AIRBNB')}
              </div>
              {channels !== '' && (
                <div>
                  <Select
                    className='w-full mt-4 rounded-xl'
                    //value={value}
                    defaultValue={0}
                    disabled={gathrenListingLoading}
                    onChange={(v) => setProperty(v?.target?.value)}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          borderRadius: '24px',
                          color: '#9CA3AF', 
                          borderColor: '#9CA3AF',
                        },
                      },
                    }}
                    style={{
                      borderRadius: '24px',
                      color: '#9CA3AF',
                      borderColor: '#9CA3AF',
                    }}
                  >
                    <MenuItem value={0} style={{ color: '#9CA3AF', display: 'none' }}>
                      {t('NEW_LOCKS.APARTMENT')}
                    </MenuItem>
                    {
                    channels === "BOOKING"? <RenderAddNew />: 
                    channels === 'GATHREN'
                      ? gathernUnits.map((cc: any) => { 
                          return (
                            <MenuItem key={cc?.id} disabled={cc?.isConncted} value={cc} style={{ color: '#9CA3AF' }}>
                              {cc?.title}
                            </MenuItem>
                          );
                        })
                      :getAirbnbListingLoading || getAirbnbListingRefreshing  ? <Box sx={{width:'100%',height:'100px',display:'flex',justifyContent:'center',alignItems:'center'}} >
                       
                         <CircularProgress size={20} />
                      </Box> : !AirbnbUnits || AirbnbUnits?.length ==0 ? <RenderAddNew /> : getAirbnbListing?.response?.data?.listing_id_dictionary?.values?.map((property: any) => (
                          <MenuItem
                            key={property?.id}
                            disabled={property?.isConncted}
                            value={property}
                            style={{ color: '#9CA3AF' }}
                          >
                            {property?.title}
                          </MenuItem>
                        ))}
                  </Select>
                </div>
              )}
            </div>

            <div className='mt-6'>
              <Button
                disabled={channels === '' || property == null}
                className='w-full'
                variant={'contained'}
                size={'large'}
                onClick={() => {
                  let  chaletId =  channels === 'GATHREN'  ? property?.chalet_id :0  
                  return connectNewChannelMutaion({
                    propertyId: data?.propertyId,
                    gathrenUnitId: channels === 'GATHREN' ? `${property?.id}` : '',
                    airbnbUnitId: channels === 'GATHREN' ? `` : `${property?.id}`, 
                    chaletId
                  });
                }}
              >
                {t('NEW_LOCKS.LINK')}
              </Button>
            </div>
            <div className='flex items-center gap-2 text-sm justify-center mt-4'>
              <div className='text-[#A1A1AA]'>{t('PROPERTIES_MANAGEMENT.CLICKING_LINK')}</div>
            </div>
          </>
        </Box>
      </Modal>
    </>
  );
};

export default ConnectNewChannel;
