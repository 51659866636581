import styled from "styled-components"

export const ControlsWrapper = styled.div`
	p {
		margin: 0;
		padding: 0;
	}
	.HeaderControls {
		font-size: 14px;
		font-weight: 700;
		line-height: 16.8px;
		color: #331860;
		margin-bottom: 20px;
	}
	.plansPeriodToggel {
		padding: 4px;
		display: grid;
		grid-template-columns: 1fr 1fr;
		background-color: #f3f3f3;
		border-radius: 8px;
		div {
			border-radius: 4px;
			padding: 8px;
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;
			p {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 10px;
				span {
					&:first-child {
						color: #261b6a99;
						font-size: 14px;
						font-weight: 400;
						line-height: 16.8px;
					}
					&.discount {
						font-size: 10px;
						font-weight: 400;
						line-height: 12px;
						color: #2a8147;
						padding: 6px 4px;
						background-color: #e6f4ea;
						border-radius: 4px;
					}
				}
			}
			&.active {
				background-color: #ffffff;
				box-shadow: 0px 2px 3px 0px #0000000a;
				span {
					&:first-child {
						font-size: 14px;
						font-weight: 700;
						color: #5b3fff;
					}
					&.discount {
						font-size: 10px;
						font-weight: 400;
						line-height: 12px;
						color: #2a8147;
						padding: 6px 4px;
						background-color: #e6f4ea;
						border-radius: 4px;
					}
				}
			}
		}
	}
	.containerCountOfProperty {
		margin-top: 20px;
	}
`
