import { UseMutationOptions, useMutation } from "@tanstack/react-query"
import { AxiosInstance, AxiosResponse } from "axios"
import { IPropsRegister, IResponseRegister } from "./types"
import { useApi } from "../../useApi"
import { registerURL } from "../../../utils/constants"

async function postRegister(data: IPropsRegister, apiPrivate: AxiosInstance) {
	try {
		const response: AxiosResponse<IResponseRegister> = await apiPrivate.post(
			`${registerURL}`,
			{ ...data }
		)

		return response
	} catch (error: any) {
		return error.response
	}
}

export function usePostRegister(
	options?:
		| Omit<
				UseMutationOptions<
					AxiosResponse<IResponseRegister>,
					unknown,
					unknown,
					AxiosResponse<IResponseRegister>
				>,
				"mutationFn"
		  >
		| undefined
) {
	const { apiPrivate } = useApi()
	const mutation = useMutation({
		mutationFn: (data: IPropsRegister) => postRegister(data, apiPrivate),
		...options,
	})
	return mutation
}
