import { useTranslation } from "react-i18next"
import { ControlsWrapper } from "./styles"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import Input from "@Common/input"
import { FORM_REGEX_VALIDATORS } from "@utils"
interface IForm {
	NUM_OF_PROPERTY: string
}

interface IProps {
	setsubscriptionType: Dispatch<SetStateAction<"Monthly" | "Yearly">>
	setnumberOfProperties: Dispatch<SetStateAction<number>>
}
export default function Controls({
	setsubscriptionType,
	setnumberOfProperties,
}: IProps) {
	const [selectedPlan, setSelectedPlan] = useState<"Monthly" | "Yearly">(
		"Yearly"
	)
	const { t } = useTranslation()
	const methods = useForm<IForm>({
		mode: "onChange",
	})
	const {
		setValue,
		watch,
		formState: { errors },
	} = methods
	const NUM_OF_PROPERTY_Watch = watch("NUM_OF_PROPERTY")
	const onSubmit = async (value: IForm) => {
		setnumberOfProperties(Number(value.NUM_OF_PROPERTY))
	}
	useEffect(() => {
		if (selectedPlan) {
			setsubscriptionType(selectedPlan)
		}
	}, [setsubscriptionType, selectedPlan])

	useEffect(() => {
		if (FORM_REGEX_VALIDATORS.numbersOnly.test(NUM_OF_PROPERTY_Watch)) {
			setnumberOfProperties(Number(NUM_OF_PROPERTY_Watch))
		}
	}, [NUM_OF_PROPERTY_Watch, errors])
	useEffect(() => {
		setValue("NUM_OF_PROPERTY", "1")
	}, [setValue])
	return (
		<ControlsWrapper>
			<div>
				<p className='HeaderControls'>{t("paymentPlan.Billing_period")}</p>
				<div className='plansPeriodToggel'>
					<div
						className={selectedPlan === "Yearly" ? "active" : ""}
						onClick={() => setSelectedPlan("Yearly")}
					>
						<p>
							<span>{t("paymentPlan.annually")}</span>
							<span className='discount'>
								{t("paymentPlan.discount", { number: "11" })}%
							</span>
						</p>
					</div>
					<div
						className={selectedPlan === "Monthly" ? "active" : ""}
						onClick={() => setSelectedPlan("Monthly")}
					>
						<p>
							<span>{t("paymentPlan.monthly")}</span>
						</p>
					</div>
				</div>
			</div>
			<div className='containerCountOfProperty'>
				<FormProvider {...methods}>
					<form onSubmit={methods.handleSubmit(onSubmit)}>
						<Input
							label={t("PROPERTIES_MANAGEMENT.NUM_OF_PROPERTY")}
							placeholder={t("PROPERTIES_MANAGEMENT.NUM_OF_PROPERTY")}
							name='NUM_OF_PROPERTY'
							registerOptions={{
								pattern: {
									value: FORM_REGEX_VALIDATORS.numbersOnly,
									message: t("COMMON.THE_FIELD_ACCEPTS_NUMBERS_ONLY"),
								},
								required: {
									value: true,
									message: t("COMMON.REQUIRED"),
								},
							}}
						/>
					</form>
				</FormProvider>
			</div>
		</ControlsWrapper>
	)
}
