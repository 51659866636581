import GathernLogo from '@assets/svg/gathren.svg?react';
import AirBnbLogo from '@assets/svg/airbnb.svg?react';
import Booking from  '@assets/svg/Booking.svg?react';
import SmallBooking from '@assets/svg/smallBooking.svg?react';
import SmallBooking2 from '@assets/svg/smallBooking2.svg?react';
import MsoolIcon2 from '@assets/svg/MsoolIcon2.svg?react';

import AgodSoon from '@assets/svg/AgodSoon.svg?react';
import GatherInSoon from '@assets/svg/GatherInSoon.svg?react';
import ExpediaSoon from '@assets/svg/ExpediaSoon.svg?react';


import { ReactNode } from 'react';

export const platformConfig: Record<number, { color: string; logo: ReactNode; label: string }> = {
  0: {
    color: '#ff5a5f',
    logo: <AirBnbLogo />,
    label: 'AIRBNB',
  },
  1: { 
    color: '#5a409b',
    logo: <GatherInSoon />,
    label: 'GATHERIN',
  },
  2: {
    color: '#003580',
    logo: <SmallBooking2/>, 
    label: 'Booking',
  },
  3: {
    color: '#003580',
    logo: <MsoolIcon2/>, 
    label: 'Msool',
  },
  4: {
    color: '#5a409b',
    logo: <AgodSoon  />, 
    label: 'Agooda',
  },
  5: {
    color: '#5a409b',
    logo: <ExpediaSoon  />, 
    label: 'Expedia',
  },
 
};