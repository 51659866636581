import styled from "styled-components"

export const CheckBoxLabelInputWrapper = styled.div`
	.card {
		padding: 20px 12px;
		border: 2px solid #e2e8f0;
		border-radius: 12px;
		cursor: pointer;
		transition: all 0.3s ease-in-out;
		width: 100%;
		background-color: #fff;
		box-shadow: 0px 8px 8px 0px #f1f5f9;
		height: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 12px;
		&.selected {
			border-color: #5b3fff;
		}
		.card-checkbox {
			/* display: none; */
		}
		.card-label {
			height: 100%;
			display: flex;
			justify-content: flex-end;
			align-items: flex-start;
			flex-direction: column;
			gap: 10px;
			cursor: pointer;
			.card-title {
				font-size: 16px;
				font-weight: 700;
				line-height: 19.2px;
				color: #261b6a;
				margin: 0;
			}
		}
	}
`
