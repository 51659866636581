import styled from "styled-components"

export const StepperStateWrapper = styled.div`
	.stepperCotainer {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 12px;
		div {
			border: 1px solid #f2f2f2fa;
			flex-basis: 100%;
			height: 7px;
			border-radius: 50px;
			overflow: hidden;
			background-color: #f2f2f2fa;
			&.active {
				border: 1px solid #5b3fff;
				background-color: #5b3fff;
			}
		}
	}
`
