import styled from "styled-components"

export const FormStepOneWrapper = styled.div`
	overflow: hidden;
	display: grid;
	grid-template-rows: auto 1fr;
	gap: 30px;
	.containerHeader {
		* {
			margin: 0;
			padding: 0;
		}
		h1 {
			font-size: clamp(18px, 5vw, 24px);
			font-weight: 700;
			line-height: 34px;
			letter-spacing: -0.025em;
			color: #261b6a;
		}
		p {
			font-size: clamp(12px, 5vw, 16px);
			font-weight: 400;
			line-height: 45px;
			letter-spacing: -0.025em;
			color: #8e98a1;
		}
	}
	form {
		display: grid;
		grid-template-rows: 1fr auto;
		gap: 30px;
		.containerCards {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 16px;
		}
	}
`
