import { UseQueryOptions, useQuery } from "@tanstack/react-query"
import { APIS_KEYS } from "../../apis-keys"
import {
	IPropsGetActiveSubscription,
	IResponseGetActiveSubscription,
} from "./types"
import type { AxiosInstance, AxiosResponse } from "axios"
import { useApi } from "../../useApi"
import { getActiveSubscriptionURL } from "../../../utils/constants"
async function getActiveSubscription(
	{ ...rest }: IPropsGetActiveSubscription,
	apiPrivate: AxiosInstance
) {
	try {
		const response: AxiosResponse<IResponseGetActiveSubscription> =
			await apiPrivate.get(`${getActiveSubscriptionURL}`, {
				params: rest,
			})
		return response.data
	} catch (error: any) {
		console.log(error)
		throw new Error("something went wrong")
	}
}

export function useGetActiveSubscription(
	data: IPropsGetActiveSubscription,
	options?:
		| Omit<
				UseQueryOptions<
					IResponseGetActiveSubscription,
					unknown,
					IResponseGetActiveSubscription
				>,
				"queryKey" | "queryFn"
		  >
		| undefined
) {
	const { apiPrivate } = useApi()
	return useQuery({
		queryKey: [APIS_KEYS.GETACTIVITYSUBSCRIPTION, ...Object.values(data)],
		queryFn: () => getActiveSubscription(data, apiPrivate),
		...options,
	})
}
