enum features  {'profile', 'sideMenu', 'autoAccess','dashboard', 'managePlan','rules','category','booking','dm','taskManagment','websiteBuilder','controlCenter'}

const disabledFeatures: Record<keyof typeof features, boolean> = {
  sideMenu: import.meta.env.VITE_FEATURE_MENU === 'false',
  profile: import.meta.env.VITE_FEATURE_PROFILE === 'false',
  autoAccess: import.meta.env.VITE_FEATURE_AUTO_ACCESS === 'false',
  managePlan: import.meta.env.VITE_MANAGE_PLAN === 'false',
  dashboard: import.meta.env.VITE_FEATURE_DASHBOARD === 'false',
  rules: import.meta.env.VITE_AVAILABLILITY_RULES === 'false', 
  category:import.meta.env.VITE_CATEGORY === 'false', 
  booking:import.meta.env.VITE_BOOKING === 'false', 
  dm:import.meta.env.VITE_DM === 'false',
  taskManagment:import.meta.env.VITE_TASK === 'false',
  websiteBuilder:import.meta.env.VITE_WBSITE_BUILDER === 'false',
  controlCenter:import.meta.env.VITE_CONTROL_CENTER === 'false'
};

export { disabledFeatures };
