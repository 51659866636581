import styled from "styled-components"

export const DescriptionSectionWrapper = styled.div`
	height: 100%;
	width: 100%;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0 16px 16px 0;
  overflow: hidden;
	.containerBG {
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}
	.description {
		z-index: 1;
		padding-inline: clamp(20px, 5vw, 70px);
		* {
			margin: 0;
			padding: 0;
			color: white;
		}
		h1 {
			text-align: center;
			font-size: clamp(20px, 5vw, 40px);
			font-weight: 700;
			line-height: 48px;
			margin-bottom: 16px;
		}
		p {
			font-size: 24px;
			font-weight: 400;
			line-height: 28.8px;
			text-align: center;
		}
	}
`
