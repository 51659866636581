import styled from "styled-components"

export const FormStepTwoWrapper = styled.div`
	overflow: hidden;
	display: grid;
	grid-template-rows: auto 1fr;
	gap: 30px;
	.containerHeader {
		* {
			margin: 0;
			padding: 0;
		}
		h1 {
			font-size: clamp(18px, 5vw, 24px);
			font-weight: 700;
			line-height: 34px;
			letter-spacing: -0.025em;
			color: #261b6a;
		}
		p {
			font-size: clamp(12px, 5vw, 16px);
			font-weight: 400;
			line-height: 45px;
			letter-spacing: -0.025em;
			color: #8e98a1;
		}
	}

	.containerInputs {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 16px;
		> div {
			&:nth-child(1),
			&:nth-child(2) {
				grid-column: span 1;
			}
			&:nth-child(n + 3) {
				grid-column: span 2;
			}
		}
	}
	.other {
		margin-top: 12px;
		> div {
			> label {
				> p {
					font-size: 12px;
					font-weight: 400;
					line-height: 14.4px;
				}
				.inputcontainer {
					input {
						&::placeholder {
							font-size: 12px;
							font-weight: 400;
							line-height: 14.4px;
						}
					}
				}
			}
		}
	}
`
