import { UseQueryOptions, useQuery } from "@tanstack/react-query"
import { APIS_KEYS } from "../../apis-keys"
import { IResponseGetOnboardingChannels } from "./types"
import type { AxiosInstance, AxiosResponse } from "axios"
import { useApi } from "../../useApi"
import { getOnboardingChannelsURL } from "../../../utils/constants"

async function getOnboardingChannels(apiPrivate: AxiosInstance) {
	try {
		const response: AxiosResponse<IResponseGetOnboardingChannels> =
			await apiPrivate.get(`${getOnboardingChannelsURL}`)
		return response.data
	} catch (error: any) {
		console.log(error)
		throw new Error("something went wrong")
	}
}

export function useGetOnboardingChannels(
	options?:
		| Omit<
				UseQueryOptions<
					IResponseGetOnboardingChannels,
					unknown,
					IResponseGetOnboardingChannels
				>,
				"queryKey" | "queryFn"
		  >
		| undefined
) {
	const { apiPrivate } = useApi()
	return useQuery({
		queryKey: [APIS_KEYS.GETONBOARDINGCHANNELS],
		queryFn: () => getOnboardingChannels(apiPrivate),
		...options,
	})
}
