import { UseMutationOptions, useMutation } from "@tanstack/react-query"
import { AxiosInstance, AxiosResponse } from "axios"
import { IPropsSaveChannelsSurvey, IResponseSaveChannelsSurvey } from "./types"
import { useApi } from "../../useApi"
import { SaveChannelsSurveyURL } from "../../../utils/constants"

async function postSaveChannelsSurvey(
	responseData: IPropsSaveChannelsSurvey,
	apiPrivate: AxiosInstance
) {
	try {
		const response: AxiosResponse<IResponseSaveChannelsSurvey> =
			await apiPrivate.post(`${SaveChannelsSurveyURL}`, responseData.data)

		return response
	} catch (error: any) {
		return error.response
	}
}

export function usePostSaveChannelsSurvey(
	options?:
		| Omit<
				UseMutationOptions<
					AxiosResponse<IResponseSaveChannelsSurvey>,
					unknown,
					unknown,
					AxiosResponse<IResponseSaveChannelsSurvey>
				>,
				"mutationFn"
		  >
		| undefined
) {
	const { apiPrivate } = useApi()
	const mutation = useMutation({
		mutationFn: (data: IPropsSaveChannelsSurvey) =>
			postSaveChannelsSurvey(data, apiPrivate),
		...options,
	})
	return mutation
}
