// @ts-nocheck
import { lazy } from "react"
const handleCatch = (e) => {
	console.log("eee=>", e)
}
const Terms = lazy(() => import("./Terms").catch(() => handleCatch()))
const Register = lazy(() => import("./Register").catch(() => handleCatch()))
const AfterPayment = lazy(() =>
	import("./AfterPayment").catch(() => handleCatch())
)
const ResetPassword = lazy(() =>
	import("./ResetPassword").catch(() => handleCatch())
)
const SignupPage = lazy(() => import("./signup").catch(() => handleCatch()))
const Login = lazy(() => import("./Login").catch(() => handleCatch()))
const NewCalenderPage = lazy(() =>
	import("./NewCalenderPage").catch((e) => handleCatch(e))
)
const Finance = lazy(() => import("./Finance").catch(() => handleCatch()))
const FinanceDetails = lazy(() =>
	import("./FinanceDetails").catch(() => handleCatch())
)
const LandingPage = lazy(() =>
	import("./LandingPage").catch(() => handleCatch())
)
const LocksManagement = lazy(() =>
	import("./LocksManagement").catch(() => handleCatch())
)
const LockDetails = lazy(() =>
	import("./LockDetails").catch(() => handleCatch())
)
const LocksListing = lazy(() =>
	import("./LocksListing").catch(() => handleCatch())
)
const PropertiesManagement = lazy(() =>
	import("./PropertiesManagement").catch(() => handleCatch())
)
const CategoryManagementPage = lazy(() =>
	import("./CategoryManagementPage").catch(() => handleCatch())
)

const NewPropertyProfile = lazy(() =>
	import("./NewPropertyProfile").catch(() => handleCatch())
)
const PropertyProfile = lazy(() =>
	import("./PropertyProfile").catch(() => handleCatch())
)
const Settings = lazy(() => import("./Settings").catch(() => handleCatch()))
const Dashboard = lazy(() => import("./Dashboard").catch(() => handleCatch()))
const Messaging = lazy(() => import("./Messaging").catch(() => handleCatch()))
const ManagePlan = lazy(() => import("./ManagePlan").catch(() => handleCatch()))
const ControlCenter = lazy(() =>
	import("./ControlCenter").catch(() => handleCatch())
)
const Subscribe = lazy(() => import("./Subscribe").catch(() => handleCatch()))
const Passwordchange = lazy(() =>
	import("./PasswordChange").catch(() => handleCatch())
)
const PasswordForget = lazy(() =>
	import("./PasswordForget").catch(() => handleCatch())
)
const PasswordCode = lazy(() =>
	import("./PasswordPin").catch(() => handleCatch())
)
const ManualReservationPage = lazy(() =>
	import("./manualReservation").catch(() => handleCatch())
)
const ReservationManagementPage = lazy(() =>
	import("./ReservationManagementPage").catch(() => handleCatch())
)
const GuestManagementPage = lazy(() =>
	import("./GuestManagementPage").catch(() => handleCatch())
)
const OwnerManagementPage = lazy(() =>
	import("./OwnerMangemnt").catch(() => handleCatch())
)
const AddOwner = lazy(() => import("./OwnerAdd").catch(() => handleCatch()))
const OwnerInsights = lazy(() =>
	import("./OwnerInsights").catch(() => handleCatch())
)
const OwnerEdit = lazy(() => import("./OwnerEdit").catch(() => handleCatch()))

const DirectMessagesPage = lazy(() =>
	import("./DirectMessages").catch(() => handleCatch())
)
const TaskManagetmenPage = lazy(() =>
	import("./TaskManagement").catch(() => handleCatch())
)
const GuestPoral = lazy(() =>
	import("./GuestPortal").catch(() => handleCatch())
)
const WebsiteBuilder = lazy(() =>
	import("./WebsiteBuilder").catch(() => handleCatch())
)
import ErrorPage from "./ErrorPage"

export {
	Terms,
	Register,
	AfterPayment,
	ResetPassword,
	Login,
	SignupPage,
	NewCalenderPage,
	Finance,
	FinanceDetails,
	LandingPage,
	ErrorPage,
	LocksManagement,
	LockDetails,
	LocksListing,
	PropertiesManagement,
	NewPropertyProfile,
	PropertyProfile,
	Settings,
	Messaging,
	ManagePlan,
	Dashboard,
	ControlCenter,
	Subscribe,
	Passwordchange,
	PasswordForget,
	PasswordCode,
	ManualReservationPage,
	ReservationManagementPage,
	GuestManagementPage,
	CategoryManagementPage,
	OwnerManagementPage,
	AddOwner,
	OwnerInsights,
	OwnerEdit,
	DirectMessagesPage,
	TaskManagetmenPage,
	GuestPoral,
	WebsiteBuilder
}
