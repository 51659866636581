import { UseQueryOptions, useQuery } from "@tanstack/react-query"
import { APIS_KEYS } from "../../apis-keys"
import { IResponseGetUserDetail } from "./types"
import type { AxiosInstance, AxiosResponse } from "axios"
import { useApi } from "../../useApi"
import { GetUserDetail } from "../../../utils/constants"
async function getUserDetail(apiPrivate: AxiosInstance) {
	try {
		const response: AxiosResponse<IResponseGetUserDetail> =
			await apiPrivate.get(`${GetUserDetail}`)
		return response.data
	} catch (error: any) {
		console.log(error)
		throw new Error("something went wrong")
	}
}

export function useGetUserDetail(
	options?:
		| Omit<
				UseQueryOptions<
					IResponseGetUserDetail,
					unknown,
					IResponseGetUserDetail
				>,
				"queryKey" | "queryFn"
		  >
		| undefined
) {
	const { apiPrivate } = useApi()
	return useQuery({
		queryKey: [APIS_KEYS.GETUSERDETAILS],
		queryFn: () => getUserDetail(apiPrivate),
		...options,
	})
}
