import { modalContainerStyle } from "@utils"
import { Modal } from "@mui/material"
import { Dispatch, SetStateAction, useState } from "react"
import StepperSignup from "../stepperSignup"
import SubscriptionPlans from "../SubscriptionPlans"
interface IProps {
	open: boolean
	setShowSupscriptionModal: Dispatch<SetStateAction<boolean>>
}
const SupscriptModal = ({ open = false, setShowSupscriptionModal }: IProps) => {
	const [openViewNum, setOpenViewNum] = useState<1 | 2>(1)
	return (
		<Modal
			open={open}
			sx={{ ...modalContainerStyle }}
			aria-labelledby='add-message-modal-title'
			aria-describedby='add-message-modal-description'
		>
			{openViewNum === 1 ? (
				<StepperSignup setOpenViewNum={setOpenViewNum} />
			) : (
				<SubscriptionPlans
					setShowSupscriptionModal={setShowSupscriptionModal}
				/>
			)}
		</Modal>
	)
}
export default SupscriptModal
