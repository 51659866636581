import { Grid } from "@mui/material"
import FormSection from "./components/formSection"
import { SignupComponentsWrapper } from "./styles"
import DescriptionSection from "./components/descriptionSection"
export default function SignupComponents() {
	return (
		<SignupComponentsWrapper>
			<Grid container sx={{ height: "100%" }}>
				<Grid item xs={12} md={6}>
					<FormSection />
				</Grid>
				<Grid
					item
					sx={{
						display: {
							xs: "none",
							sm: "none",
							md: "block",
						},
					}}
					sm={6}
				>
					<DescriptionSection />
				</Grid>
			</Grid>
		</SignupComponentsWrapper>
	)
}
