import { Controller, RegisterOptions, useFormContext } from "react-hook-form"
import { CardInputWrapper } from "./styles"

interface ICardCheckboxProps {
	id: string // Checkbox ID
	title: string
	imageUrl: string | JSX.Element
	name: string // Form field name
	registerOptions?: RegisterOptions
}

export default function CardInput({
	id,
	title,
	imageUrl,
	name,
	registerOptions,
}: ICardCheckboxProps) {
	const { control } = useFormContext()
	return (
		<CardInputWrapper>
			<Controller
				control={control}
				name={name}
				rules={registerOptions}
				render={({ field: { onChange, value = [] } }) => {
					const isChecked = value.includes(id)
					const handleCheckboxChange = () => {
						if (isChecked) {
							onChange(value.filter((item: string) => item !== id))
						} else {
							onChange([...value, id])
						}
					}

					return (
						<div className={`card ${isChecked ? "selected" : ""}`}>
							<input
								type='checkbox'
								id={id}
								className='card-checkbox'
								checked={isChecked}
								onChange={handleCheckboxChange}
							/>
							<label htmlFor={id} className='card-label'>
								<div className='card-image'>
									{typeof imageUrl === "string" ? "" : imageUrl}
								</div>
								<p className='card-title'>{title}</p>
							</label>
						</div>
					)
				}}
			/>
		</CardInputWrapper>
	)
}
