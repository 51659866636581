import { useTranslation } from "react-i18next"
import { SubscriptionPlansWrapper } from "./styles"
import { Grid } from "@mui/material"
import Plans from "./plans"
import Controls from "./controls"
import { useGetSubscriptionPlans } from "@services"
import { useState } from "react"
interface IProps {
	setShowSupscriptionModal: React.Dispatch<React.SetStateAction<boolean>>
}
export default function SubscriptionPlans({
	setShowSupscriptionModal,
}: IProps) {
	const [numberOfProperties, setnumberOfProperties] = useState<number>(1)
	const [subscriptionType, setsubscriptionType] = useState<
		"Monthly" | "Yearly"
	>("Monthly")
	const { t } = useTranslation()
	const { data } = useGetSubscriptionPlans(
		{
			numberOfProperties: numberOfProperties,
			subscriptionType: subscriptionType,
		},
		{
			enabled:
				numberOfProperties !== undefined || subscriptionType !== undefined,
		}
	)
	return (
		<SubscriptionPlansWrapper>
			<div>
				<div className='Header'>
					<h1>{t("paymentPlan.Choose_your_package_now")}</h1>
					<p>
						{t(
							"paymentPlan.Start_your_journey_in_real_estate_management_with_the_highly_efficient_Masool_platform"
						)}
					</p>
				</div>

				<Grid container>
					<Grid item xs={12} md={2}>
						<Controls
							setsubscriptionType={setsubscriptionType}
							setnumberOfProperties={setnumberOfProperties}
						/>
					</Grid>
					<Grid item xs={12} md={1}></Grid>
					<Grid item xs={12} md={8}>
						<Grid
							container
							spacing={{
								xs: 2,
							}}
						>
							{data?.result.map((item, index) => {
								return (
									<Grid item xs={12} sm={6} md={4} key={index}>
										<Plans
											numberOfProperties={numberOfProperties}
											subscriptionType={subscriptionType}
											id={item.packageWithsFeature[0].packageId}
											packageType={item.packageType as "Monthly" | "Yearly"}
											features={item.packageWithsFeature[0].features}
											noOfProperty={item.noOfProperty}
											old_price=''
											priceForEachProperty={item.priceForEachProperty}
											title={item.packageName}
											setShowSupscriptionModal={setShowSupscriptionModal}
										/>
									</Grid>
								)
							})}
						</Grid>
					</Grid>
				</Grid>
			</div>
		</SubscriptionPlansWrapper>
	)
}
