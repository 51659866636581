import React, { Dispatch, FC, SetStateAction, useMemo } from "react"
import { IProperty } from "@interfaces"
import {
	Box,
	Checkbox,
	Chip,
	FormControlLabel,
	MenuItem,
	Select,
	Typography,
} from "@mui/material"
import { Clear } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import AccessInstructions, {
	IFormAccessInstructions,
} from "./AccessInstructions"

const SelectProperty: FC<{
	templates?: any[]
	setTab?: React.Dispatch<React.SetStateAction<number>>
	property: IProperty[]
	selected: IProperty[]
	usedProperties?: IProperty[]
	setSelected: Dispatch<SetStateAction<IProperty[]>>
	setTemplateDetailsState?: Dispatch<
		SetStateAction<IFormAccessInstructions | undefined>
	>
}> = ({
	property,
	selected,
	setSelected,
	usedProperties,
	setTab,
	setTemplateDetailsState,
	templates
}) => {
	const { t } = useTranslation()

	const errors = useMemo(() => {
		return (
			selected.filter((s) =>
				usedProperties?.some((u) => u.propertyId === s.propertyId)
			) ?? []
		)
	}, [usedProperties, selected])
	const selectProperties = (ids: number[]) => {
		setSelected(() => property.filter((i) => ids.includes(i.propertyId)))
	}
	const removeProperty = (p: IProperty) => {
		setSelected((prev) => prev.filter((i) => i.propertyId !== p.propertyId))
	}
	return (
		<Box
			sx={{
				pt: 3,
				px: 1,
				overflowY: "auto",
				maxHeight: "100%",
				flexGrow: 1,
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Typography mb={2} color={"primary"}>
				{t("MESSAGING.CHOOSE_PROPERTIES")}
			</Typography>
			<Box
				sx={{
					display: "flex",
					height: "100%",
					".MuiFormControlLabel-root": {
						marginInline: 0,
					},

					flexDirection: "column",
					gap: 1,
				}}
			>
				{errors.map((e) => {
					return (
						<Typography color={"error"} key={e.propertyId}>
							{t("MESSAGING.USED_PROPERTY_ERROR", { property: e.propertyName })}
						</Typography>
					)
				})}
				<Select
					multiple
					fullWidth
					value={selected.map((p) => p.propertyId) ?? []}
					onChange={(event) =>
						selectProperties((event.target.value as number[]) ?? [])
					}
				>
					{property?.map((i) => {
						return (
							<MenuItem value={i.propertyId} key={i.propertyId}>
								{i.propertyName}
							</MenuItem>
						)
					})}
				</Select>
				<FormControlLabel
					control={
						<Checkbox
							checked={
								selected.length > 0 && property.length === selected.length
							}
							onChange={(e) => {
								if (e.target.checked) {
									setSelected(property)
								} else {
									setSelected([])
								}
							}}
						/>
					}
					label={t("MESSAGING.ALL_PROPERTIES")}
				/>
				<AccessInstructions
					selected={selected}
					removeProperty={removeProperty}
					setTab={setTab}
					setTemplateDetailsState={setTemplateDetailsState}
					templates={templates}
				/>
			</Box>
		</Box>
	)
}
export { SelectProperty }
