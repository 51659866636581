import { useTranslation } from "react-i18next"
import { FormSectionWrapper } from "./stylex"
import FormInputs from "./formInputs"
import { Container } from "@mui/material"

export default function FormSection() {
	const { t } = useTranslation()
	return (
		<FormSectionWrapper>
			<Container maxWidth='sm'>
				<div>
					<h1>{t("Signup.signup")}</h1>
				</div>
				<FormInputs />
			</Container>
		</FormSectionWrapper>
	)
}
