export const ATOMS_KEYS = {
	CONTROLEADVANCEDSETTINGS: "CONTROLEADVANCEDSETTINGS",
	REINITIALIZECALENDAR: "REINITIALIZECALENDAR",
	ISMONTHLYVIEW: "ISMONTHLYVIEW",
	GETDAYSOFWEEK: "GETDAYSOFWEEK",
	CONTROLEBOOKINGDETAILS: "CONTROLEBOOKINGDETAILS",
	GITBOOKINGINFO: "GITBOOKINGINFO",
	CLASSFICATION: "CLASSFICATION",
	DMDATA: "DMDATA",
	DMLIVEFEEDEVENTID: "DMLIVEFEEDEVENTID",
	DMDATARESERVATIONREQUEST: "DMDATARESERVATIONREQUEST",
	NEWCALENDERPAGE: "NEWCALENDERPAGE",
	SELECTEDPROPERTY: "SELECTEDPROPERTY",
	SEARCHBYNAME: "SEARCHBYNAME",
	POPUPOVERLAYCALENDER: "POPUPOVERLAYCALENDER",
	RESERVATIONDETAILS: "RESERVATIONDETAILS",
	LOADERSTATE: "LOADERSTATE",
	SWITCHRESERVATIONSTATES: "SWITCHRESERVATIONSTATES",
	CURRENTPAGESTORE: "CURRENTPAGESTORE",
	PRAMSLOCKID: "PRAMSLOCKID",
	LOCKDETAILSSTORE: "LOCKDETAILSSTORE",
	GETUSERDETAILS: "GETUSERDETAILS",
	SUBSCRIPTIONPLANNAME: "SUBSCRIPTIONPLANNAME",
}
