export const loginURL = "/api/Auth/Login"
export const registerURL = "/api/Auth/Register"
export const ListManagementURL = "/api/ListingManagement"
export const ListManagementCardNumbersURL = "/api/ListingManagement/CardsNumber"
export const createPropertyProfileURL =
	"/api/ListingManagement/CreatePropertyListingProfile"
export const AccountingCreateIncomeURL = "/api/CreateRevenue"
export const AccountingGetIncomeURL = "/api/GetAllRevenue"
export const AccountingUpdateIncomeURL = "/api/UpdateRevenue"
export const AccountingDeleteIncomeURL = "/api/DeleteRevenue"
export const AccountingCreateExpenseURL = "/api//CreateFinancialTransaction"
export const AccountingGetExpenseURL = "/api//GetAllFinancialTransaction"
export const AccountingUpdateExpenseURL = "/api//UpdateFinancialTransaction"
export const AccountingDeletexpenseURL = "/api//DeleteFinancialTransaction"
export const CheckBookingConnectionURL = "/api/Booking/TestConnection"
export const GetBookingMappingDetailsURL =
	"/api/Booking/GetMappingDetails?hotelId="
export const SaveBookingChannelURL = "/api/Booking/SaveChannel"
export const saveAirbnbToBookingApiURL = "/api/Booking/SaveBookingChannel"
export const SyncBookingPriceURL = "/api/ChannexPriceFullSync"

export const UpdateCHannelsResterictionURL = "/api/UpdateChannexRestrictions"

export const AllChannelsURL = "/api/GetAllChannles"
export const AllCategoriesURL = "/api/GetAllCategories"
export const ReasonsDeletionURL = "/api/ReasonsDeletion"
export const AllCitiesURL = "/api/GetAllCities"

export const DashboardURL = "/api/Dashboard"

export const GetAllOperatorURL = "/api/get-all-operator"
export const RequestNewOperatorURL = "/api/request-new-operator"
export const AddLockURL = "/api/add-lock"
export const UnlockURL = "/api/unlock-door"
export const SetAutoTimeLockURL = "/api/create-emergency-code"
export const UpdateLockIdURL = "/api/update-lock-id"
export const GeneratePassCodeForLockURL = "/api/generate-passcode-for-lock"
export const GetActivePasscodeURL = "/api/get-active-passcode"
export const GetPassCodeHistoryURL = "/api/get-passcode/history"
export const GetLockStateURL = "/api/get-lock-state"
export const getLockBatteryURL = "/api/get-lock-battery"
export const GetAllLocksURL = "/api/get-locks-listing"
export const DeleteLockURL = "/api/delete-lock"
export const GetLocksControlURL = "/api/get-locks-control"
export const GetPropertyDetailsURL = "/api/get-property-details"
export const DisablePassCode = "/api/disable-passcode"
export const GetLockDetailsUrl = "/api/get-reservation-info-with-lock-details"
export const getLockStatusURL = "/api/get-lock-status-info"
export const GathrenLoginURL = "/api/Gathren_Login"
export const GathrenVerifyOtpURL = "/api/Gathren_VerifyOTP"
export const GathrenListingURL = "/api/Gathren_Listing"
export const getCalendarURL = "/api/gathren_calendar"
export const getMessagesURL = "/api/get-all-messages"
export const createTemplateURL = "/api/create-template"
export const updateTemplateURL = "/api/update-template"
export const deleteTemplateURL = "/api/delete-template"
export const getUserTemplatesURL = "/api/get-user-templates"
export const assignTemplateToListURL = "/api/get-template"
export const AirbnbCreateGroupAndPropertyURL =
	"/api/Airbnb_CreateGroupAndProperty"
export const AirbnbChannelConnectionUrl = "/api/Airbnb_ChannelConnection"
export const MsoolCalendarUrl = "/api/GetCalendar"

export const getAirbnbListingURL = "/api/Airbnb_Listing"
export const getCalendaAibnbURL = "/api/airbnb_calendar"
export const UserApi = "/api/user"

export const getSubscriptionPlansURL = "/api/get-subscriptions-plans"
export const getPrintInvoiceURL = "/api/print-Invoice"
export const PayNowURL = "/api/PayNow"
export const getSubscriptionFeaturesURL = "/api/get-subscription-features"
export const downgradeSubscriptionURL = "/api/downgrade-subscription"

export const payAndUpgradeSubscriptionURL = "/api/pay-and-upgradesubscription"
export const getActiveSubscriptionInfoURL = "/api/get-active-subscriptions-info"
export const getActiveSubscriptionURL = "/api/get-active-subscription"
export const getInvoicesURL = "/api/get-invoices"
export const getCustomerSubscriptionsURL =
	"/api/get-customer-subscriptions-with-properties"
export const calculateSubscriptionURL = "/api/get-selected-subscription-details"
export const checkoutURL = "/api/CheckOutAndPay"
export const airBnbPriceURL = "/api/Airbnb_Price"
export const AirbnbUpdateAvailabilityRulesURL =
	"/api/Airbnb_UpdateAvailabilityRules"
export const UpdatePricingDiscountForAllChannelsURL =
	"/api/UpdatePricingDiscountForAllChannels"
export const airBnbMinStayURL = "/api/Airbnb_MinStay"
export const airBnbUpdateAvailabilityURL = "/api/Airbnb_UpdateAvailability"
export const getPropertyFinancialReportURL =
	"/api/GetFinancialReportByPropertyId"
export const getFinancialReportsURL = "/api/GetFinancialReports"
export const afterPaymentURL = "/api/AfterPayment"
export const AllChannelsAvailabilityURL = "/api/UpdateAvailabilityInChannels"

export const getControlCenterUrl = "/api/ControlCenterDashboard"
export const updateControlCenterUrl = "/api/UpdateTheAutoCycle"
export const sendWhatssAppMessageUrl =
	"/api/send-whatsup-message?reservationId="
export const sendWhatssAppMessageUrlV2 = "/api/send-whatsup-message-v2"
export const InstantBookSettingsURL = "/api/InstantBookSettings"

export const Airbnb_InstantBookURL = "/api/Airbnb_InstantBook"
export const OnboardingGuideURL = "/api/OnboardingGuid"
export const AllPlatformPriceURL = "/api/UpdatePriceForAllChannels"
export const GatherinAvailabilityURL = "/api/Gathren_RemoveBusyToProperty"
export const TuyaQrURL = "/api/get-qr-code"
export const DeActivateListingURL =
	"/api/ListingManagement/DeactivatingListing/"
export const RequestPasswordOtpURL = "/api/Auth/SendOTP"
export const VerfiyForgetOtpURL = "/api/Auth/VerifyOTP"
export const ForgetPasswordChangeURL = "/api/Auth/ForgetPassword"

export const ManualReservationAPI = "/api/ManualReservation"

export const ReservationsUrl = "/api/Reservations"
export const ReservationCardUrl = "/api/ReservationCard"
export const GuestManagementUrl = "/api/GuestManagement"
export const GuestManagementClassificationUrl =
	"/api/GuestManagementClassification"

export const GetListingManagementCategoriesUrl =
	"/api/ListingManagement/Categories/GetCategories"
export const GetPropertiesDoNotHaveCategoryUrl =
	"/api/ListingManagement/Categories/GetPropertiesDoNotHaveCategory"
export const GetCategoryDetailsByIdUrl =
	"/api/ListingManagement/Categories/GetCategoryDetailsById"
export const UpdateCategoryUrl =
	"/api/ListingManagement/Categories/UpdateCategory"
export const CreateCategoryUrl =
	"/api/ListingManagement/Categories/CreateCategory"
export const DeleteCategoryUrl =
	"/api/ListingManagement/Categories/DeleteCategory"

export const GetOwnerUrl = "/api/OwnerManagement"
export const GetOwnerByIdUrl = "/api/OwnerManagement"
export const UpdateOwnerUrl = "/api/OwnerManagement"
export const DeleteOwnerUrl = "/api/OwnerManagement"
export const CreateOwnerUrl = "/api/OwnerManagement"
export const GetPropertiesWithNoOwnerUrl =
	"/api/OwnerManagement/GetPropertiesDoNotHaveOwner"
export const GetRentalAndExpensesByOwnerIdUrl =
	"/api/OwnerManagement/GetRentalAndExpensesByOwnerId"

export const GetAllMessageThreadsUrl = "/api/Channex_GetAllMessageThreads"
export const Channex_GetMessageThreadsByIdUrl =
	"/api/Channex_GetMessageThreadsById"
export const Channex_GetMessagesUrl = "/api/Channex_GetMessages"
export const Channex_MessageThread_SendMessagetUrl =
	"/api/Channex_MessageThread_SendMessage"
export const Channex_GetLiveEventByIdUrl = "/api/Channex_GetLiveEventById"
export const RejectReasonUrl = "/api/RejectReason"
export const AirbnbHandleReservationRequestUrl =
	"/api/Airbnb_Handle_Reservation_Request"
export const TaskMangementUrl = "/api/TaskManagement"
export const AlternativeRequestUrl = "/api/ResolveAlterationRequest"
export const WelcomeBookApi = "/api/guest-welcome-book"


export const WebsiteBuilderGetDomain = "/api/WebsiteBuilder/GetDomain"
export const WebsiteBuilderSaveDomain = "/api/WebsiteBuilder/SaveDomain"
export const WebsiteBuilderCheckAvailability = "/api/WebsiteBuilder/CheckAvailability"
export const WebsiteBuilderGetProperties = "/api/WebsiteBuilder/GetPorpreties"
export const WebsiteBuilderAddOrUpdatePorpretiesListing = "/api/WebsiteBuilder/AddOrUpdatePorpretiesListing"
export const WebsiteBuilderUploadLogo = "/api/WebsiteBuilder/UploadLogo"
export const WebsiteBuilderCreateOrUpdateWebsiteSettings = "/api/WebsiteBuilder/CreateOrUpdateWebsiteSettings"
export const WebsiteBuilderGetWebsiteSettings = "/api/WebsiteBuilder/GetWebsiteSettings"
export const WebsiteBuilderUpdateVisability = "/api/WebsiteBuilder/UpdateVisibility"
export const getOnboardingChannelsURL = "/api/GetOnboardingChannels"
export const getOnboardingMotivesURL = "/api/GetOnboardingMotives"
export const SaveChannelsSurveyURL = "/api/SaveChannelsSurvey"
export const SaveMotivesSurveyURL = "/api/SaveMotivesSurvey"
export const GetUserDetail = "/api/User/GetUserDetail"
export const UploadWebSiteBuilderPaymentsFiles= "/api/Payment/UploadFiles"
export const GetWebSiteBuilderPaymentStatus = "/api/Payment/GetPayment"
export const WebsiteBuilderActivatePayments = "/api/Payment/ActivePayment"
export const ActiveOrDeActiveGatewayURL = '/api/Payment/ActiveOrDeActiveGateway'