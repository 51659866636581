import { useEffect, useState } from "react"
import { CustomPhoneNumberInputWrapper } from "./styles"
import PhoneInput, {
	isValidPhoneNumber,
	DefaultInputComponentProps,
} from "react-phone-number-input"
import "react-phone-number-input/style.css"
import { useTranslation } from "react-i18next"
import { Controller, RegisterOptions, useFormContext } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"

interface ICustomPhoneNumberInputProps extends DefaultInputComponentProps {
	name: string
	registerOptions?: RegisterOptions
	placeholder?: string
	label?: string
	withOutErrorMessage?: boolean
}
export default function CustomPhoneNumberInput({
	label,
	name,
	registerOptions,
	placeholder,
	withOutErrorMessage,
	...rest
}: ICustomPhoneNumberInputProps) {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	/* ------------------------------- Local State ------------------------------ */
	const [isRequired, setIsRequired] = useState(false)
	/* ---------------------------------- Hooks --------------------------------- */
	const { control, formState, setError } = useFormContext()
	const { errors } = formState

	useEffect(() => {
		if (typeof registerOptions?.required === "object") {
			setIsRequired(registerOptions?.required?.value)
		}
		if (typeof registerOptions?.required === "boolean") {
			setIsRequired(registerOptions.required)
		}
	}, [registerOptions])

	return (
		<CustomPhoneNumberInputWrapper
			$language={language as "ar" | "en"}
			$isError={errors[name] ? true : false}
		>
			<label>
				{label}
				{isRequired && <span>*</span>}
			</label>

			<Controller
				name={name}
				control={control}
				rules={{
					...registerOptions,
					validate: (value) => {
						if (!value) return true
						if (!isValidPhoneNumber(value)) return t("ERRORS.INVALID_MOBILE")
						return true
					},
				}}
				render={({ field: { onChange, value } }) => {
					return (
						<div>
							<PhoneInput
								{...rest}
								international
								placeholder={placeholder}
								value={value}
								onChange={onChange}
							/>
						</div>
					)
				}}
			/>

			{!withOutErrorMessage && (
				<div className='ErrorMessageStyle'>
					<ErrorMessage
						errors={errors}
						name={name}
						render={({ message }) => <p>{message}</p>}
					/>
				</div>
			)}
		</CustomPhoneNumberInputWrapper>
	)
}
