import { useTranslation } from "react-i18next"
import { FormStepTwoWrapper } from "./styles"
import { FormProvider, useForm } from "react-hook-form"
import { Dispatch, SetStateAction, useContext, useMemo } from "react"
import CheckBoxLabelInput from "../checkBoxLabelInput"
import TextArea from "@Common/Textarea"
import { useGetOnboardingMotives, usePostSaveMotivesSurvey } from "@services"
import { ToastMessageContext } from "@context/toast-message-cotnext"
interface IForm {
	questions: string[]
	other: string
}
interface IProps {
	setOpenViewNum: Dispatch<SetStateAction<1 | 2>>
}
export default function FormStepTwo({ setOpenViewNum }: IProps) {
	/* ---------------------------------- Hooks --------------------------------- */
	const { showWarning } = useContext(ToastMessageContext)
	const { data: GetOnboardingMotives } = useGetOnboardingMotives()
	const { mutateAsync: mutateAsyncSaveMotivesSurvey } =
		usePostSaveMotivesSurvey()
	const { t } = useTranslation()
	const methods = useForm<IForm>({
		defaultValues: {
			questions: [],
		},
	})
	const { watch } = methods
	const otherElement = watch("questions")
	/* -------------------------------- Functions ------------------------------- */
	const onSubmit = async (value: IForm) => {
		if (value.questions.length === 0)
			return showWarning(t("paymentPlan.please_select_at_least_one"))

		const data = value.questions.map((item) => {
			return {
				optionId: item,
				value: item === "202" ? value.other : "",
			}
		})
		const res = await mutateAsyncSaveMotivesSurvey({
			data,
		})
		if (res.status === 200) {
			setOpenViewNum(2)
		}
	}
	const dataList = useMemo(() => {
		const data = GetOnboardingMotives?.result?.map((item) => {
			return {
				id: item.id,
				title: item.name,
			}
		})
		return data ?? []
	}, [GetOnboardingMotives])

	return (
		<FormStepTwoWrapper>
			<div className='containerHeader'>
				<h1>{t("Signup.We_will_be_by_your_side_to_achieve_your_goals")}</h1>
				<p>{t("Signup.What_motivates_you_to_use_the_Msool_platform")}</p>
			</div>
			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)} id='FormStepTwo'>
					<div className='containerInputs'>
						{dataList.map((item) => {
							return (
								<CheckBoxLabelInput
									id={item.id.toString()}
									name='questions'
									title={item.title}
									key={item.id}
								/>
							)
						})}
						{otherElement.includes("202") && (
							<div className='other'>
								<TextArea
									name='other'
									label={t(
										"Signup.Help_us_find_your_goal_so_we_can_achieve_it_together"
									)}
									placeholder={t(
										"Signup.Help_us_find_your_goal_so_we_can_achieve_it_together"
									)}
								/>
							</div>
						)}
					</div>
				</form>
			</FormProvider>
		</FormStepTwoWrapper>
	)
}
