import { Dispatch, FC, SetStateAction } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { platformConfig, disabledFeatures } from '@utils';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SoonFlag from '@assets/svg/SoonFlag.svg?react';

const StepOne: FC<{
  setChannel: Dispatch<SetStateAction<string | undefined>>;
  channel?: string;
  onLink: () => void;
  property: any
}> = ({ channel, setChannel, onLink, property }) => {
  const { t } = useTranslation();
  const isAirbnbLinked = property?.airbnbUnitId?.length
  const isBookingLinked = property?.bookingUnitId?.length
  const  isGathrinLinked = property?.gathrenUnitId?.length
  console.log('sultan',property)
  return (
    <Box sx={{ flex: 1, pt: 4, pb: 2, display: 'flex', flexDirection: 'column' }}>
      <Typography color={'#0F172A'} fontSize={'20px'}>
        {t('ADD_PROPERTY.CHOOSE_CHANNEL')}
      </Typography>
      <Typography color={'#8E98A1'} fontSize={'16px'}>
        {t('ADD_PROPERTY.AVAILABLE_CHANNELS')}
      </Typography>

      <Box
        sx={{
          pt: 3,
          display: 'grid',
          gridTemplateColumns: { xs: 'repeat(2,1fr)', sm: 'repeat(3,1fr)' },
          gap: 2,
          marginBottom: 4,
          borderBlockEnd: '1px solid #EAEDF1',
          paddingBottom: 3,
        }}
      >
        <Box
          onClick={() => {
            if (isAirbnbLinked) return
            setChannel('airbnb')
          }}
          sx={{
            aspectRatio: 1,
            border: '2px solid',
            borderColor: channel == 'airbnb' ? '#5B3FFF' : '#EEEBFF',
            backgroundColor: channel == 'airbnb' ? '#EEEBFF' : '#FFF',
            cursor: channel == 'airbnb' || isAirbnbLinked ? 'auto' : 'pointer',
            borderRadius: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              svg: {
                width: '48px',
                height: '48px',
              },
            }}
          >
            {platformConfig[0].logo}
          </Box>
          <Typography sx={{ mt: 1 }} >{t(platformConfig[0].label)}</Typography>
          {channel == 'airbnb' && <CheckCircleIcon color={'primary'} fontSize={'small'} />}
          {isAirbnbLinked && <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }} >
            <CheckCircleIcon color={'primary'} fontSize={'small'} />
            <Typography sx={{ fontWeight: 700, fontSize: '11px', mx: 0.2, mt: 0.5 }} >
              {t('ADD_PROPERTY.ALREADY_LINKED')}
            </Typography>

          </Box>}
        </Box>
        {/* <Box
          onClick={() => setChannel('gathern')}
          sx={{
            aspectRatio: 1,
            border: '2px solid',
            borderColor: channel == 'gathern' ? '#5B3FFF' : '#EEEBFF',
            backgroundColor: channel == 'gathern' ? '#EEEBFF' : '#FFF',
            cursor: channel == 'gathern' ? 'auto' : 'pointer',
            borderRadius: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column', 
          }}
        >
          <Box
            sx={{
              svg: {
                width: '48px',
                height: '48px',
              },
            }}
          >
            {platformConfig[1].logo}
          </Box>
          <Typography>{t(platformConfig[1].label)}</Typography>
          {channel == 'gathern' && <CheckCircleIcon color={'primary'} fontSize={'small'} />}
        </Box> */}
        {disabledFeatures.booking ? null : <Box
          onClick={() => {
            if (isBookingLinked) return
            setChannel('booking')
          }}
          sx={{
            aspectRatio: 1,
            border: '2px solid',
            borderColor: channel == 'booking' ? '#5B3FFF' : '#EEEBFF',
            backgroundColor: channel == 'booking' ? '#EEEBFF' : '#FFF',
            cursor: channel == 'gathern' || isBookingLinked ? 'auto' : 'pointer',
            borderRadius: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              svg: {
                width: '48px',
                height: '48px',
              },
            }}
          >
            {platformConfig[2].logo}
          </Box>
          <Typography>{t(platformConfig[2].label)}</Typography>
          {channel == 'booking' && <CheckCircleIcon color={'primary'} fontSize={'small'} />}
          {isBookingLinked && <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }} >
            <CheckCircleIcon color={'primary'} fontSize={'small'} />
            <Typography sx={{ fontWeight: 700, fontSize: '11px', mx: 0.2, mt: 0.5 }} >
              {t('ADD_PROPERTY.ALREADY_LINKED')}
            </Typography>

          </Box>}
        </Box>}
        <Box
          onClick={() => {
            if(isGathrinLinked)return
           
            setChannel('gathern')
            // setChannel('booking')
          }}
          sx={{
            aspectRatio: 1,
            border: '2px solid',
            borderColor: channel=='gathern' ? '#5B3FFF' : '#EEEBFF',
            backgroundColor: false ? '#EEEBFF' : '#FFF',
            cursor:isGathrinLinked?'default': 'pointer',
            borderRadius: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            position: 'relative'
          }}
        >
          {/* <SoonFlag style={{left:2,top:10,width:'61px',height:'21px',position:'absolute'}} /> */}

          <Box
            sx={{
              svg: {
                width: '48px',
                height: '48px',
              },
            }}
          >
            {platformConfig[1].logo}
          </Box>
          <Typography>{t(platformConfig[1].label)}</Typography>
          {isGathrinLinked && <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }} >
            <CheckCircleIcon color={'primary'} fontSize={'small'} />
            <Typography sx={{ fontWeight: 700, fontSize: '11px', mx: 0.2, mt: 0.5 }} >
              {t('ADD_PROPERTY.ALREADY_LINKED')}
            </Typography>

          </Box>}

        </Box>
        <Box
          sx={{
            aspectRatio: 1,
            border: '2px solid',
            borderColor: false ? '#5B3FFF' : '#EEEBFF',
            backgroundColor: false ? '#EEEBFF' : '#FFF',
            cursor: channel == 'gathern' || isBookingLinked ? 'auto' : 'pointer',
            borderRadius: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            position: 'relative'
          }}
        >
          <SoonFlag style={{ left: 2, top: 10, width: '61px', height: '21px', position: 'absolute' }} />

          <Box
            sx={{
              svg: {
                width: '48px',
                height: '48px',
              },
            }}
          >
            {platformConfig[3].logo}
          </Box>
          <Typography>{t(platformConfig[3].label)}</Typography>

        </Box>
        <Box
          sx={{
            aspectRatio: 1,
            border: '2px solid',
            borderColor: false ? '#5B3FFF' : '#EEEBFF',
            backgroundColor: false ? '#EEEBFF' : '#FFF',
            cursor: channel == 'gathern' || isBookingLinked ? 'auto' : 'pointer',
            borderRadius: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            position: 'relative'
          }}
        >
          <SoonFlag style={{ left: 2, top: 10, width: '61px', height: '21px', position: 'absolute' }} />
          <Box
            sx={{
              svg: {
                width: '48px',
                height: '48px',
              },
            }}
          >
            {platformConfig[4].logo}
          </Box>
          <Typography>{t(platformConfig[4].label)}</Typography>

        </Box>
      </Box>
      <Box sx={{ mt: 'auto', display: 'flex', gap: 8, alignItems: 'center' }}>
        <Button size={'small'} variant={'contained'} onClick={onLink} disabled={!channel}>
          {t('ADD_PROPERTY.LINK')}
        </Button>
        <Typography color={'#8E98A1'}>{t('ADD_PROPERTY.LINK_HELPER')}</Typography>
      </Box>
    </Box>
  );
};

export default StepOne;
