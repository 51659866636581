import styled from "styled-components"

export const CardInputWrapper = styled.div`
	.card {
		border: 2px solid #e2e8f0;
		border-radius: 12px;
		cursor: pointer;
		transition: all 0.3s ease-in-out;
		width: 100%;
		background-color: #fff;
		box-shadow: 0px 8px 8px 0px #f1f5f9;
		height: 100%;
		&.selected {
			border-color: #5b3fff;
		}
		.card-checkbox {
			display: none;
		}
		.card-label {
			height: 100%;
			display: flex;
			justify-content: flex-end;
			align-items: flex-start;
			flex-direction: column;
			gap: 10px;
			cursor: pointer;
			padding: 24px;
			.card-image {
				width: 28px;
				height: 28px;
				object-fit: contain;
				img,
				svg {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}

			.card-title {
				font-size: 16px;
				font-weight: 700;
				line-height: 19.2px;
				color: #331860;
				margin: 0;
			}
		}
	}
`
