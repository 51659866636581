import CustomButton from "@Common/CustomButton"
import { PlansWrapper } from "./styles"
import { useTranslation } from "react-i18next"
import { IoIosCheckmark } from "react-icons/io"
import { useNavigate } from "react-router-dom"
import { Dispatch, SetStateAction } from "react"
interface IProps {
	title: string
	priceForEachProperty: string
	old_price: string
	noOfProperty: string
	features: string[]
	packageType: "Monthly" | "Yearly"
	setShowSupscriptionModal: Dispatch<SetStateAction<boolean>>
	id: number
	numberOfProperties: number
	subscriptionType: "Monthly" | "Yearly"
}
export default function Plans({
	title,
	priceForEachProperty,
	old_price,
	noOfProperty,
	features,
	packageType,
	setShowSupscriptionModal,
	numberOfProperties,
	subscriptionType,
	id,
}: IProps) {
	const { t } = useTranslation()
	const navigate = useNavigate()
	return (
		<PlansWrapper>
			<div className='infoSection'>
				<p>{title}</p>
				<div className='compo'>
					<p>
						<span>
							{priceForEachProperty} {t("GENERAL.SR")}
						</span>
						{old_price && (
							<span className='Strikethrough'>
								{old_price} {t("GENERAL.SR")}
							</span>
						)}
					</p>
					<p className='plan'>
						{packageType === "Yearly"
							? t("paymentPlan.annually")
							: t("paymentPlan.monthly")}{" "}
						/ {t("FOR_EACH_PROPERTY")}
					</p>
				</div>
				<div className='number_property'>
					{noOfProperty}{" "}
					{packageType === "Monthly"
						? t("MONTHLY_SUBSCRIPTION")
						: t("YEARLY_SUBSCRIPTION")}
				</div>
			</div>
			<div className='selectThePlan'>
				<CustomButton
					label={t("paymentPlan.select")}
					withBG
					customPadding='16px'
					borderRadius='50px'
					onClick={() => {
						setShowSupscriptionModal(false)
						navigate({
							pathname: `/subscribe/${id}/${numberOfProperties}`,
							search: `?subscriptionType=${subscriptionType}`,
						})
					}}
				/>
			</div>
			<div>
				<div className='containerRow'>
					{features.map((item, index) => {
						return (
							<p key={index}>
								<span>
									<IoIosCheckmark />
								</span>
								<span>{item}</span>
							</p>
						)
					})}
				</div>
			</div>
		</PlansWrapper>
	)
}
