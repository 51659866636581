import { subscriptionPlanNameStore } from "@store/subscriptionPlanName"
import { useRecoilValue } from "recoil"
import { IResultGetUserDetail } from "src/services/home/getUserDetail/types"

export function UserHasPermission(
	permission: IResultGetUserDetail["subscriptionPlanName"][]
) {
	const getStorePermissions = useRecoilValue(subscriptionPlanNameStore)
	const thePermission = permission?.includes(getStorePermissions)
	return thePermission
}
