export const APIS_KEYS = {
	GETCALENDERDATA: "GETCALENDERDATA",
	GETAIRBNBCALENDAR: "GETAIRBNBCALENDAR",
	GETLISTINGMANAGEMENT: "GETLISTINGMANAGEMENT",
	GETLOCKDETAILS: "GETLOCKDETAILS",
	GETSENDWHATSUPMESSAGE: "GETSENDWHATSUPMESSAGE",
	GETMANUALRESERVATION: "GETMANUALRESERVATION",
	GETCLASSIFICATION: "GETCLASSIFICATION",
	GETSUBSCRIPTIONPLAN: "GETSUBSCRIPTIONPLAN",
	GETONBOARDINGCHANNELS: "GETONBOARDINGCHANNELS",
	GETONBOARDINGMOTIVES: "GETONBOARDINGMOTIVES",
	CALCULATESUBSCRIPTION: "CALCULATESUBSCRIPTION",
	CHECKOUTURL: "CHECKOUTURL",
	GETACTIVITYSUBSCRIPTION: "GETACTIVITYSUBSCRIPTION",
	GETUSERDETAILS: "GETUSERDETAILS",
	GETACTIVEPLAN: "GETACTIVEPLAN",
	GETINVOICES: "GETINVOICES",
	GETPRINTINVOICE: "GETPRINTINVOICE",
}
